import React, {memo} from 'react';
import {View} from 'react-native';
import {Text, useTheme} from 'react-native-paper';
import ThemedIcon from './ThemedIcon';

const SearchRireki = (props: {text: string; onSpeak: () => void}) => {
  const {colors} = useTheme();
  return (
    <View style={{flexDirection: 'row', alignItems: 'center'}}>
      <Text
        variant="bodyLarge"
        style={{color: colors.primary, paddingRight: 10}}>
        {` `}
        {props.text}
      </Text>
      <ThemedIcon name={'volume-high'} onPress={props.onSpeak} />
    </View>
  );
};

export default memo(SearchRireki);
