import React, {memo} from 'react';
import {Platform} from 'react-native';
import {Appbar} from 'react-native-paper';
import {ThemeContext} from '../common/hooks/themeContext';
import ToggleTheme from './ToggleTheme';

interface Props {
  title: string;
  children?: React.ReactNode;
}

const JAppbarHeader: React.FC<Props> = ({title, children}) => {
  const {preferredTheme, toggleTheme} = React.useContext(ThemeContext);
  return (
    <Appbar.Header mode={Platform.OS === 'web' ? 'center-aligned' : 'small'}>
      <Appbar.Content title={title} titleStyle={{fontWeight: 'bold'}} />
      {children}
      <ToggleTheme preferredTheme={preferredTheme} toggleTheme={toggleTheme} />
    </Appbar.Header>
  );
};

export default memo(JAppbarHeader);
