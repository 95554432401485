import React, {memo} from 'react';
import {Snackbar, useTheme} from 'react-native-paper';
import {KTJ} from '../common/constants';

const JSnackbar = (props: {
  visible: boolean;
  message: string;
  setVisible: (flag: boolean) => void;
}) => {
  const {colors} = useTheme();
  const onDismissSnackBar = () => props.setVisible(false);

  return (
    <Snackbar
      visible={props.visible}
      onDismiss={onDismissSnackBar}
      action={{
        label: `${KTJ.text.snackbar.label}`,
        onPress: () => onDismissSnackBar(),
      }}
      style={{backgroundColor: colors.onBackground}}>
      {props.message}
    </Snackbar>
  );
};

export default memo(JSnackbar);
