/**
  2021年12月スマートフォンOSシェア調査
  https://mmdlabo.jp/investigation/detail_2012.html
    iPhone SE 2 - 375 x 667
    iPhone 8 - 375 x 667
    iPhone 8 Plus - 414 x 736
    iPhone XS - 375 x 812
    SM-G920S - 360 x 640
    FWVGA 5.4 - 480 x 854
    Nexus 6 - 411.42857142857144 x 715.4285714285714
    Nexus 5 - 360 x 624
    Nexus 4 - 384 x 624
    Pixel 3 XL - 411.42857142857144 x 748.8571428571429
 */
import {Dimensions} from 'react-native';
// import * as Device from 'expo-device';
// import DeviceInfo from 'react-native-device-info';

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;
// const isSlow = (() => {
//   const dtm = Device.totalMemory;
//   const smallmem = 17179869184;
//   return dtm < smallmem;
// })();

export default {
  // model: Device.modelName,
  // hasNotch: DeviceInfo.hasNotch(), // emulator에서 model명이 sdk_gphone64_arm64 처럼 표시되므로 동작 X
  width: width,
  height: height,
  isNarrow: width <= 360, // SM-G920S, Nexus 5
  isNarrowWeb: width <= 768, // Web only, iPad: 768x1024
  isLong: height > 667, // iPhone SE 2
  isShort: height <= 640, // SM-G920S
  // isSlow: isSlow,
};
