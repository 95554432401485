export const hanenglish = [
  {freq: 0, shiin: 'a', ruby: {ja: 'a'}},
  {freq: 0, shiin: 'b', ruby: {ja: 'b'}},
  {freq: 0, shiin: 'c', ruby: {ja: 'c'}},
  {freq: 0, shiin: 'd', ruby: {ja: 'd'}},
  {freq: 0, shiin: 'e', ruby: {ja: 'e'}},
  {freq: 0, shiin: 'f', ruby: {ja: 'f'}},
  {freq: 0, shiin: 'g', ruby: {ja: 'g'}},
  {freq: 0, shiin: 'h', ruby: {ja: 'h'}},
  {freq: 0, shiin: 'i', ruby: {ja: 'i'}},
  {freq: 0, shiin: 'j', ruby: {ja: 'j'}},
  {freq: 0, shiin: 'k', ruby: {ja: 'k'}},
  {freq: 0, shiin: 'l', ruby: {ja: 'l'}},
  {freq: 0, shiin: 'm', ruby: {ja: 'm'}},
  {freq: 0, shiin: 'n', ruby: {ja: 'n'}},
  {freq: 0, shiin: 'o', ruby: {ja: 'o'}},
  {freq: 0, shiin: 'p', ruby: {ja: 'p'}},
  {freq: 0, shiin: 'q', ruby: {ja: 'q'}},
  {freq: 0, shiin: 'r', ruby: {ja: 'r'}},
  {freq: 0, shiin: 's', ruby: {ja: 's'}},
  {freq: 0, shiin: 't', ruby: {ja: 't'}},
  {freq: 0, shiin: 'u', ruby: {ja: 'u'}},
  {freq: 0, shiin: 'v', ruby: {ja: 'v'}},
  {freq: 0, shiin: 'w', ruby: {ja: 'w'}},
  {freq: 0, shiin: 'x', ruby: {ja: 'x'}},
  {freq: 0, shiin: 'y', ruby: {ja: 'y'}},
  {freq: 0, shiin: 'z', ruby: {ja: 'z'}},
  {freq: 0, shiin: 'A', ruby: {ja: 'A'}},
  {freq: 0, shiin: 'B', ruby: {ja: 'B'}},
  {freq: 0, shiin: 'C', ruby: {ja: 'C'}},
  {freq: 0, shiin: 'D', ruby: {ja: 'D'}},
  {freq: 0, shiin: 'E', ruby: {ja: 'E'}},
  {freq: 0, shiin: 'F', ruby: {ja: 'F'}},
  {freq: 0, shiin: 'G', ruby: {ja: 'G'}},
  {freq: 0, shiin: 'H', ruby: {ja: 'H'}},
  {freq: 0, shiin: 'I', ruby: {ja: 'I'}},
  {freq: 0, shiin: 'J', ruby: {ja: 'J'}},
  {freq: 0, shiin: 'K', ruby: {ja: 'K'}},
  {freq: 0, shiin: 'L', ruby: {ja: 'L'}},
  {freq: 0, shiin: 'M', ruby: {ja: 'M'}},
  {freq: 0, shiin: 'N', ruby: {ja: 'N'}},
  {freq: 0, shiin: 'O', ruby: {ja: 'O'}},
  {freq: 0, shiin: 'P', ruby: {ja: 'P'}},
  {freq: 0, shiin: 'Q', ruby: {ja: 'Q'}},
  {freq: 0, shiin: 'R', ruby: {ja: 'R'}},
  {freq: 0, shiin: 'S', ruby: {ja: 'S'}},
  {freq: 0, shiin: 'T', ruby: {ja: 'T'}},
  {freq: 0, shiin: 'U', ruby: {ja: 'U'}},
  {freq: 0, shiin: 'V', ruby: {ja: 'V'}},
  {freq: 0, shiin: 'W', ruby: {ja: 'W'}},
  {freq: 0, shiin: 'X', ruby: {ja: 'X'}},
  {freq: 0, shiin: 'Y', ruby: {ja: 'Y'}},
  {freq: 0, shiin: 'Z', ruby: {ja: 'Z'}},
];
