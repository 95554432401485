import React, {memo, useRef, useMemo, useState, useEffect} from 'react';
import {
  View,
  Easing,
  Platform,
  Animated,
  FlatList,
  StyleSheet,
} from 'react-native';
import {Text, Divider, useTheme, Searchbar, Portal} from 'react-native-paper';
import {flattenDeep, random} from 'lodash';
import * as Speech from 'expo-speech';
import {hansetsus, patchims} from './common/datas';
import {KTJ, themedWidths} from './common/constants';
import {useForceUpdate} from './common/hooks';
import {
  GuideMenu,
  JSnackbar,
  JFooter,
  ThemedIcon,
  SearchResult,
  SearchRireki,
  JAppbarHeader,
} from './components';

interface HistoryType {
  idx: string;
  ko: string;
  ja: string;
}

interface SpeechOpts {
  language: string;
  pitch?: number;
  rate?: number;
  volume?: number;
  voice: string;
}
const hanguls = [...hansetsus, ...patchims];
const histories: HistoryType[] = [];

const Main = () => {
  const {colors} = useTheme();
  const [searchQuery, setSearchQuery] = useState('');
  const [ruby, setRuby] = useState<string[]>([]);
  const [visible, setVisible] = useState(false);
  const [voicesja, setVoicesja] = useState<Speech.Voice[]>([]);
  const [voicesko, setVoicesko] = useState<Speech.Voice[]>([]);
  const animValue = useRef(new Animated.Value(0)).current;
  const forceUpdate = useForceUpdate(); // 히스토리 리스트 갱신중

  const onSpeakToJa = async (text: string) => {
    /** vsja값 추출: useEffect() 빈값 처리중 */
    const _language = 'ja-JP';
    let vsja: Speech.Voice[] = [...voicesja];
    if (!voicesja.length) {
      const vs = await Speech.getAvailableVoicesAsync();
      vsja = vs.filter(v => v.language === _language);
      setVoicesja(vsja);
    }

    const isSpeaking = await Speech.isSpeakingAsync();
    if (isSpeaking) Speech.stop();
    const _jaids = vsja.map(v => v.identifier);

    let options: SpeechOpts = {
      language: _language,
      pitch: 1,
      rate: 0.9,
      voice: _jaids[random(_jaids.length - 1)],
    };
    if (Platform.OS === 'web')
      options = {
        ...options,
        volume: 0.5, // Web only
      };
    Speech.speak(text, options);
  };

  const onSpeakToKo = async (text: string) => {
    /** vsko값 추출: useEffect() 빈값 처리중 */
    const _language = 'ko-KR';
    let vsko: Speech.Voice[] = [...voicesko];
    if (!voicesko.length) {
      const vs = await Speech.getAvailableVoicesAsync();
      vsko = vs.filter(v => v.language === _language);
      setVoicesko(vsko);
    }

    const isSpeaking = await Speech.isSpeakingAsync();
    if (isSpeaking) Speech.stop();
    const _koids = vsko.map(v => v.identifier);

    let options: SpeechOpts = {
      language: _language,
      pitch: 1,
      rate: 0.9,
      voice: _koids[random(_koids.length - 1)],
    };
    if (Platform.OS === 'web')
      options = {
        ...options,
        volume: 0.5, // Web only
      };
    Speech.speak(text, options);
  };

  const onChangeSearch = (query: string) => {
    /** 23/10/21
     * 중간에 이빨빠진 단어를 대체문자로 처리하기어려움
     * searchQuery와 ruby 길이가 다르면 유저얼럿 표시중
     * */
    const words = query.split('');
    if (words.length) {
      const re = flattenDeep(
        words.map(w => hanguls.filter(h => h.shiin === w)),
      );
      const j = re.map(r => r.ruby.ja);
      setRuby(j);
    }
    setSearchQuery(query);
  };
  const onIconPress = () => {
    if (histories.length >= KTJ.rirekisu) {
      setVisible(true);
      return;
    }

    if (searchQuery && ruby.length) {
      histories.push({
        idx: Math.random().toString(),
        ko: searchQuery,
        ja: ruby.join(''),
      });
      forceUpdate();
    }
  };

  useEffect(() => {
    /** 백스페이스키로 삭제시 마지막 한 글자가 ruby에 남지 않도록 처리중 */
    if (!searchQuery.length) setRuby([]);
  }, [searchQuery.length]);

  useEffect(() => {
    Animated.timing(animValue, {
      toValue: 1,
      duration: 1000,
      useNativeDriver: true,
      easing: Easing.linear,
    }).start();
  }, [animValue]);

  const renderItem = ({item}: {item: HistoryType}) => {
    return (
      <View style={[styles.item, {width: themedWidths.w0905}]}>
        <SearchRireki text={item.ko} onSpeak={() => onSpeakToKo(item.ko)} />
        <SearchRireki text={item.ja} onSpeak={() => onSpeakToJa(item.ja)} />
      </View>
    );
  };
  const _container = useMemo(
    () => [
      styles.container,
      {
        opacity: animValue,
        backgroundColor: colors.background,
        // transform: [{scaleX: animValue}],
      },
    ],
    [animValue, colors.background],
  );

  return (
    <>
      <JAppbarHeader
        title={Platform.OS === 'web' ? KTJ.title.home.web : KTJ.title.home.web}
      />
      <Animated.View style={_container}>
        <View style={{width: themedWidths.w0905}}>
          <Text>
            {KTJ.text.main1} {KTJ.text.main2}
            {KTJ.text.sub1}
          </Text>
        </View>
        <GuideMenu />
        <Searchbar
          elevation={3} // default 0
          placeholder={KTJ.text.search}
          placeholderTextColor={colors.outline}
          maxLength={14} // 자음 12
          onChangeText={onChangeSearch}
          onIconPress={onIconPress}
          onClearIconPress={() => setRuby([])}
          icon={() => <ThemedIcon name={'magnify'} onPress={onIconPress} />}
          autoCorrect={false}
          autoCapitalize="none"
          autoComplete="off"
          autoFocus={true}
          value={searchQuery}
          style={[styles.searchbar, {width: themedWidths.w05095}]}
        />
        <SearchResult ruby={ruby} onSpeak={onSpeakToJa} />
        {/* 상하여백 추가중 */}
        <View style={{margin: 10}} />
        <FlatList
          data={histories}
          renderItem={renderItem}
          keyExtractor={item => item.idx.toString()}
          extraData={histories}
          scrollEventThrottle={16}
          ItemSeparatorComponent={() => <Divider />}
          keyboardShouldPersistTaps="always"
          contentContainerStyle={[styles.contentContainer]}
        />
        <Portal>
          <JSnackbar
            visible={visible}
            setVisible={setVisible}
            message={`🐶 履歴閲覧は ${KTJ.rirekisu}個 までです！`}
          />
        </Portal>
      </Animated.View>

      <JFooter />
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: '100%',
    padding: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentContainer: {flexGrow: 1},
  searchbar: {marginTop: 40, margin: 20},
  item: {
    margin: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});
export default memo(Main);
