export * from './colors';
export * from './styles';

export const URL = {
  contact: 'https://www.jacepark.com/contact/',
};

export const KTJ = {
  title: {
    home: {
      web: 'ハンクル 読み方がわかる コトジャ（Kotoja）',
      mobile: 'ハングル読み方検索',
    },
  },
  text: {
    main1: 'ハングル読み方検索機は、',
    main2:
      '韓国でよく使われる単語を検索できるように韓国国立国語研究院で発表した韓国語学習用語彙 (合計5,965語)をベースに製作しています。',
    sub1: '(検索可能な単語は随時追加されます！)',
    search: 'ハングルの読み方を調べる',
    snackbar: {label: '閉じる'},
    rireki: {title: '検索履歴'},
  },
  rirekisu: 5, // 이력 표시 갯수
};
