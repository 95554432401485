import React, {memo} from 'react';
import {Image} from 'react-native';

type Props = {
  size?: number;
};

const KotangoIcon: React.FC<Props> = ({size = 20}) => {
  return (
    <Image
      source={require('../common/assets/kotango-icon.png')}
      style={{width: size, height: size}}
    />
  );
};

export default memo(KotangoIcon);
