/**
 * 각 받침의 振り仮名 를 작성후 Hansetsu 테이블에 입력필요
 *
 * shiin: 子音
 * freq: 국립국어원의 '한국어 학습용 어휘 목록' 에서의 사용 빈도
 */

// https://wikidocs.net/176440
// ㄱ받침	k	ク’
// ㄴ받침	n	ン
// ㄷ받침	t	ッ
// ㄹ받침	l	ル’
// ㅁ받침	m	ム’
// ㅂ,ㅍ받침	p	プ’
// ㅅ받침  s ッ
// ㅇ받침	ng	ン'
// ㅈ받침  j ッ
// ㅊ받침  ch ッ
// ㅎ받침  h ッ

// 構成・発音
// https://www2.yukawa.kyoto-u.ac.jp/~shuichi.yokoyama/html/Languages/Korean.html

export const patchims = [
  {freq: 127, shiin: '적', ruby: {ja: 'チョク'}},
  {freq: 117, shiin: '장', ruby: {ja: 'チャン'}},
  {freq: 97, shiin: '전', ruby: {ja: 'チョン'}},
  {freq: 93, shiin: '인', ruby: {ja: 'イン'}},
  {freq: 93, shiin: '상', ruby: {ja: 'サン'}},
  {freq: 92, shiin: '정', ruby: {ja: 'チョン'}},
  {freq: 89, shiin: '일', ruby: {ja: 'イル'}},
  {freq: 65, shiin: '생', ruby: {ja: 'セン'}},
  {freq: 64, shiin: '신', ruby: {ja: 'シン'}},
  {freq: 64, shiin: '동', ruby: {ja: 'トン'}},
  {freq: 62, shiin: '연', ruby: {ja: 'ヨン'}},
  {freq: 60, shiin: '성', ruby: {ja: 'ソン'}},
  {freq: 58, shiin: '학', ruby: {ja: 'ハク'}},
  {freq: 58, shiin: '실', ruby: {ja: 'シル'}},
  {freq: 57, shiin: '한', ruby: {ja: 'ハン'}},
  {freq: 56, shiin: '국', ruby: {ja: 'クク'}},
  {freq: 55, shiin: '중', ruby: {ja: 'チュン'}},
  {freq: 55, shiin: '공', ruby: {ja: 'コン'}},
  {freq: 54, shiin: '문', ruby: {ja: 'ムン'}},
  {freq: 54, shiin: '관', ruby: {ja: 'コヮン'}},
  {freq: 52, shiin: '식', ruby: {ja: 'シク'}},
  {freq: 50, shiin: '원', ruby: {ja: 'ウォン'}},
  {freq: 49, shiin: '용', ruby: {ja: 'ヨン'}},
  {freq: 46, shiin: '방', ruby: {ja: 'パン'}},
  {freq: 46, shiin: '물', ruby: {ja: 'ムル'}},
  {freq: 46, shiin: '경', ruby: {ja: 'キョン'}},
  {freq: 45, shiin: '음', ruby: {ja: 'ウン'}},
  {freq: 45, shiin: '발', ruby: {ja: 'パル'}},
  {freq: 44, shiin: '만', ruby: {ja: 'マン'}},
  {freq: 44, shiin: '간', ruby: {ja: 'カン'}},
  {freq: 43, shiin: '안', ruby: {ja: 'アン'}},
  {freq: 43, shiin: '들', ruby: {ja: 'トゥル'}},
  {freq: 42, shiin: '반', ruby: {ja: 'パン'}},
  {freq: 40, shiin: '달', ruby: {ja: 'タル'}},
  {freq: 39, shiin: '통', ruby: {ja: 'トン'}},
  {freq: 39, shiin: '심', ruby: {ja: 'シム'}},
  {freq: 39, shiin: '분', ruby: {ja: 'プン'}},
  {freq: 39, shiin: '단', ruby: {ja: 'タン'}},
  {freq: 39, shiin: '금', ruby: {ja: 'クム'}},
  {freq: 37, shiin: '속', ruby: {ja: 'ソク'}},
  {freq: 37, shiin: '선', ruby: {ja: 'ソン'}},
  {freq: 36, shiin: '입', ruby: {ja: 'イプ'}},
  {freq: 36, shiin: '불', ruby: {ja: 'プル'}},
  {freq: 35, shiin: '행', ruby: {ja: 'ヘン'}},
  {freq: 35, shiin: '점', ruby: {ja: 'チョム'}},
  {freq: 35, shiin: '산', ruby: {ja: 'サン'}},
  {freq: 35, shiin: '감', ruby: {ja: 'カム'}},
  {freq: 34, shiin: '출', ruby: {ja: 'チュル'}},
  {freq: 34, shiin: '진', ruby: {ja: 'チン'}},
  {freq: 34, shiin: '양', ruby: {ja: 'ヤン'}},
  {freq: 33, shiin: '말', ruby: {ja: 'マル'}},
  {freq: 32, shiin: '명', ruby: {ja: 'ミョン'}},
  {freq: 32, shiin: '당', ruby: {ja: 'タン'}},
  {freq: 29, shiin: '영', ruby: {ja: 'ヨン'}},
  {freq: 28, shiin: '편', ruby: {ja: 'ピョン'}},
  {freq: 28, shiin: '집', ruby: {ja: 'チプ'}},
  {freq: 28, shiin: '작', ruby: {ja: 'チャク'}},
  {freq: 28, shiin: '설', ruby: {ja: 'ソル'}},
  {freq: 28, shiin: '건', ruby: {ja: 'コン'}},
  {freq: 28, shiin: '강', ruby: {ja: 'カン'}},
  {freq: 27, shiin: '남', ruby: {ja: 'ナム'}},
  {freq: 26, shiin: '근', ruby: {ja: 'クン'}},
  {freq: 26, shiin: '각', ruby: {ja: 'カク'}},
  {freq: 25, shiin: '직', ruby: {ja: 'チク'}},
  {freq: 25, shiin: '절', ruby: {ja: 'チョル'}},
  {freq: 25, shiin: '업', ruby: {ja: 'オプ'}},
  {freq: 25, shiin: '약', ruby: {ja: 'ヤク'}},
  {freq: 25, shiin: '석', ruby: {ja: 'ソク'}},
  {freq: 25, shiin: '결', ruby: {ja: 'キョル'}},
  {freq: 24, shiin: '손', ruby: {ja: 'ソン'}},
  {freq: 24, shiin: '복', ruby: {ja: 'ポク'}},
  {freq: 24, shiin: '봅', ruby: {ja: 'ポプ'}},
  {freq: 24, shiin: '력', ruby: {ja: 'リョク'}},
  {freq: 24, shiin: '등', ruby: {ja: 'トゥン'}},
  {freq: 24, shiin: '날', ruby: {ja: 'ナル'}},
  {freq: 23, shiin: '품', ruby: {ja: 'プム'}},
  {freq: 23, shiin: '판', ruby: {ja: 'パン'}},
  {freq: 23, shiin: '없', ruby: {ja: 'オップ'}},
  {freq: 23, shiin: '색', ruby: {ja: 'セク'}},
  {freq: 23, shiin: '살', ruby: {ja: 'サル'}},
  {freq: 22, shiin: '활', ruby: {ja: 'ホヮル'}},
  {freq: 22, shiin: '현', ruby: {ja: 'ヒョン'}},
  {freq: 22, shiin: '청', ruby: {ja: 'チョン'}},
  {freq: 22, shiin: '종', ruby: {ja: 'チョン'}},
  {freq: 22, shiin: '울', ruby: {ja: 'ウル'}},
  {freq: 22, shiin: '운', ruby: {ja: 'ウン'}},
  {freq: 21, shiin: '면', ruby: {ja: 'ミョン'}},
  {freq: 20, shiin: '쪽', ruby: {ja: 'ッチョク'}},
  {freq: 20, shiin: '월', ruby: {ja: 'ウォル'}},
  {freq: 20, shiin: '름', ruby: {ja: 'ルム'}},
  {freq: 20, shiin: '럽', ruby: {ja: 'ロプ'}},
  {freq: 19, shiin: '확', ruby: {ja: 'ホヮク'}},
  {freq: 19, shiin: '형', ruby: {ja: 'ヒョン'}},
  {freq: 19, shiin: '참', ruby: {ja: 'チャム'}},
  {freq: 19, shiin: '목', ruby: {ja: 'モク'}},
  {freq: 19, shiin: '망', ruby: {ja: 'マン'}},
  {freq: 18, shiin: '임', ruby: {ja: 'イム'}},
  {freq: 18, shiin: '올', ruby: {ja: 'オル'}},
  {freq: 18, shiin: '역', ruby: {ja: 'ヨク'}},
  {freq: 18, shiin: '민', ruby: {ja: 'ミン'}},
  {freq: 18, shiin: '돌', ruby: {ja: 'トル'}},
  {freq: 17, shiin: '평', ruby: {ja: 'ピョン'}},
  {freq: 17, shiin: '천', ruby: {ja: 'チョン'}},
  {freq: 17, shiin: '술', ruby: {ja: 'スル'}},
  {freq: 17, shiin: '순', ruby: {ja: 'スン'}},
  {freq: 17, shiin: '별', ruby: {ja: 'ピョル'}},
  {freq: 17, shiin: '번', ruby: {ja: 'ポン'}},
  {freq: 17, shiin: '늘', ruby: {ja: 'ヌル'}},
  {freq: 17, shiin: '년', ruby: {ja: 'ニョン'}},
  {freq: 17, shiin: '급', ruby: {ja: 'クプ'}},
  {freq: 16, shiin: '창', ruby: {ja: 'チャン'}},
  {freq: 16, shiin: '질', ruby: {ja: 'チル'}},
  {freq: 16, shiin: '증', ruby: {ja: 'チュン'}},
  {freq: 16, shiin: '담', ruby: {ja: 'タム'}},
  {freq: 15, shiin: '혼', ruby: {ja: 'ホン'}},
  {freq: 15, shiin: '필', ruby: {ja: 'ピル'}},
  {freq: 15, shiin: '접', ruby: {ja: 'チョプ'}},
  {freq: 15, shiin: '습', ruby: {ja: 'スプ'}},
  {freq: 15, shiin: '극', ruby: {ja: 'クク'}},
  {freq: 15, shiin: '격', ruby: {ja: 'キョク'}},
  {freq: 14, shiin: '환', ruby: {ja: 'ホヮン'}},
  {freq: 14, shiin: '잠', ruby: {ja: 'チャム'}},
  {freq: 14, shiin: '은', ruby: {ja: 'ウン'}},
  {freq: 14, shiin: '움', ruby: {ja: 'ウン'}},
  {freq: 14, shiin: '본', ruby: {ja: 'ポン'}},
  {freq: 14, shiin: '법', ruby: {ja: 'ポプ'}},
  {freq: 14, shiin: '벌', ruby: {ja: 'ポル'}},
  {freq: 14, shiin: '눈', ruby: {ja: 'ヌン'}},
  {freq: 14, shiin: '길', ruby: {ja: 'キル'}},
  {freq: 13, shiin: '침', ruby: {ja: 'チム'}},
  {freq: 13, shiin: '철', ruby: {ja: 'チョル'}},
  {freq: 13, shiin: '책', ruby: {ja: 'チェク'}},
  {freq: 13, shiin: '잡', ruby: {ja: 'チャプ'}},
  {freq: 13, shiin: '열', ruby: {ja: 'ヨル'}},
  {freq: 13, shiin: '앞', ruby: {ja: 'アプ'}},
  {freq: 13, shiin: '십', ruby: {ja: 'シプ'}},
  {freq: 13, shiin: '밤', ruby: {ja: 'パム'}},
  {freq: 13, shiin: '런', ruby: {ja: 'ロン'}},
  {freq: 13, shiin: '락', ruby: {ja: 'ラク'}},
  {freq: 13, shiin: '독', ruby: {ja: 'トク'}},
  {freq: 13, shiin: '님', ruby: {ja: 'ニム'}},
  {freq: 13, shiin: '난', ruby: {ja: 'ナン'}},
  {freq: 13, shiin: '군', ruby: {ja: 'クン'}},
  {freq: 12, shiin: '향', ruby: {ja: 'ヒャン'}},
  {freq: 12, shiin: '합', ruby: {ja: 'ハプ'}},
  {freq: 12, shiin: '특', ruby: {ja: 'トク'}},
  {freq: 12, shiin: '잘', ruby: {ja: 'チャル'}},
  {freq: 12, shiin: '육', ruby: {ja: 'ユク'}},
  {freq: 12, shiin: '삼', ruby: {ja: 'サム'}},
  {freq: 12, shiin: '삽', ruby: {ja: 'サプ'}},
  {freq: 12, shiin: '변', ruby: {ja: 'ピョン'}},
  {freq: 12, shiin: '못', ruby: {ja: 'モッ'}},
  {freq: 12, shiin: '먹', ruby: {ja: 'モク'}},
  {freq: 12, shiin: '록', ruby: {ja: 'ロク'}},
  {freq: 12, shiin: '람', ruby: {ja: 'ラム'}},
  {freq: 12, shiin: '답', ruby: {ja: 'タプ'}},
  {freq: 12, shiin: '능', ruby: {ja: 'ヌン'}},
  {freq: 11, shiin: '험', ruby: {ja: 'ホム'}},
  {freq: 11, shiin: '줄', ruby: {ja: 'チュル'}},
  {freq: 11, shiin: '알', ruby: {ja: 'アル'}},
  {freq: 11, shiin: '숙', ruby: {ja: 'シュク'}},
  {freq: 11, shiin: '송', ruby: {ja: 'ソン'}},
  {freq: 11, shiin: '백', ruby: {ja: 'ペク'}},
  {freq: 11, shiin: '롭', ruby: {ja: 'ロプ'}},
  {freq: 11, shiin: '놓', ruby: {ja: 'ノッ'}},
  {freq: 11, shiin: '농', ruby: {ja: 'ノン'}},
  {freq: 11, shiin: '권', ruby: {ja: 'クォン'}},
  {freq: 11, shiin: '골', ruby: {ja: 'コル'}},
  {freq: 11, shiin: '갈', ruby: {ja: 'カル'}},
  {freq: 10, shiin: '함', ruby: {ja: 'ハム'}},
  {freq: 10, shiin: '탁', ruby: {ja: 'タク'}},
  {freq: 10, shiin: '충', ruby: {ja: 'チュン'}},
  {freq: 10, shiin: '준', ruby: {ja: 'チュン'}},
  {freq: 10, shiin: '욕', ruby: {ja: 'ヨク'}},
  {freq: 10, shiin: '온', ruby: {ja: 'オン'}},
  {freq: 10, shiin: '얼', ruby: {ja: 'オル'}},
  {freq: 10, shiin: '언', ruby: {ja: 'オン'}},
  {freq: 10, shiin: '승', ruby: {ja: 'スン'}},
  {freq: 10, shiin: '밥', ruby: {ja: 'パプ'}},
  {freq: 10, shiin: '박', ruby: {ja: 'パク'}},
  {freq: 10, shiin: '막', ruby: {ja: 'マク'}},
  {freq: 10, shiin: '림', ruby: {ja: 'リム'}},
  {freq: 10, shiin: '론', ruby: {ja: 'ロン'}},
  {freq: 10, shiin: '랑', ruby: {ja: 'ラン'}},
  {freq: 10, shiin: '넘', ruby: {ja: 'ノム'}},
  {freq: 9, shiin: '항', ruby: {ja: 'ハン'}},
  {freq: 9, shiin: '축', ruby: {ja: 'チュク'}},
  {freq: 9, shiin: '존', ruby: {ja: 'チョン'}},
  {freq: 9, shiin: '족', ruby: {ja: 'チョク'}},
  {freq: 9, shiin: '잔', ruby: {ja: 'チャン'}},
  {freq: 9, shiin: '완', ruby: {ja: 'ワン'}},
  {freq: 9, shiin: '병', ruby: {ja: 'ピョン'}},
  {freq: 9, shiin: '몸', ruby: {ja: 'モム'}},
  {freq: 9, shiin: '립', ruby: {ja: 'リプ'}},
  {freq: 9, shiin: '련', ruby: {ja: 'リョン'}},
  {freq: 9, shiin: '렇', ruby: {ja: 'ロッ'}},
  {freq: 9, shiin: '논', ruby: {ja: 'ノン'}},
  {freq: 9, shiin: '김', ruby: {ja: 'キム'}},
  {freq: 9, shiin: '글', ruby: {ja: 'クル'}},
  {freq: 9, shiin: '걸', ruby: {ja: 'コル'}},
  {freq: 8, shiin: '힘', ruby: {ja: 'ヒム'}},
  {freq: 8, shiin: '흔', ruby: {ja: 'ホン'}},
  {freq: 8, shiin: '풍', ruby: {ja: 'プン'}},
  {freq: 8, shiin: '튼', ruby: {ja: 'トゥン'}},
  {freq: 8, shiin: '큰', ruby: {ja: 'クン'}},
  {freq: 8, shiin: '친', ruby: {ja: 'チン'}},
  {freq: 8, shiin: '죽', ruby: {ja: 'チュク'}},
  {freq: 8, shiin: '익', ruby: {ja: 'イク'}},
  {freq: 8, shiin: '응', ruby: {ja: 'ウン'}},
  {freq: 8, shiin: '웃', ruby: {ja: 'ウッ'}},
  {freq: 8, shiin: '악', ruby: {ja: 'アク'}},
  {freq: 8, shiin: '빨', ruby: {ja: 'ッパル'}},
  {freq: 8, shiin: '북', ruby: {ja: 'プク'}},
  {freq: 8, shiin: '밀', ruby: {ja: 'ミル'}},
  {freq: 8, shiin: '맞', ruby: {ja: 'マッ'}},
  {freq: 8, shiin: '맛', ruby: {ja: 'マッ'}},
  {freq: 8, shiin: '른', ruby: {ja: 'ルン'}},
  {freq: 8, shiin: '둘', ruby: {ja: 'トゥル'}},
  {freq: 8, shiin: '놀', ruby: {ja: 'ノル'}},
  {freq: 8, shiin: '념', ruby: {ja: 'ニョム'}},
  {freq: 8, shiin: '끝', ruby: {ja: 'ックッ'}},
  {freq: 8, shiin: '광', ruby: {ja: 'コヮン'}},
  {freq: 8, shiin: '곳', ruby: {ja: 'コッ'}},
  {freq: 8, shiin: '견', ruby: {ja: 'キョン'}},
  {freq: 8, shiin: '갑', ruby: {ja: 'カプ'}},
  {freq: 7, shiin: '흘', ruby: {ja: 'フル'}},
  {freq: 7, shiin: '풀', ruby: {ja: 'プル'}},
  {freq: 7, shiin: '팔', ruby: {ja: 'パル'}},
  {freq: 7, shiin: '탕', ruby: {ja: 'タン'}},
  {freq: 7, shiin: '칠', ruby: {ja: 'チル'}},
  {freq: 7, shiin: '찾', ruby: {ja: 'チャッ'}},
  {freq: 7, shiin: '찰', ruby: {ja: 'チャル'}},
  {freq: 7, shiin: '찬', ruby: {ja: 'チャン'}},
  {freq: 7, shiin: '짝', ruby: {ja: 'ッチャク'}},
  {freq: 7, shiin: '억', ruby: {ja: 'オク'}},
  {freq: 7, shiin: '빛', ruby: {ja: 'ピッ'}},
  {freq: 7, shiin: '득', ruby: {ja: 'トゥク'}},
  {freq: 7, shiin: '닥', ruby: {ja: 'タク'}},
  {freq: 7, shiin: '녹', ruby: {ja: 'ノク'}},
  {freq: 7, shiin: '낮', ruby: {ja: 'ナッ'}},
  {freq: 7, shiin: '곧', ruby: {ja: 'コッ'}},
  {freq: 7, shiin: '것', ruby: {ja: 'コッ'}},
  {freq: 7, shiin: '검', ruby: {ja: 'コム'}},
  {freq: 6, shiin: '할', ruby: {ja: 'ハル'}},
  {freq: 6, shiin: '틀', ruby: {ja: 'トゥル'}},
  {freq: 6, shiin: '택', ruby: {ja: 'テク'}},
  {freq: 6, shiin: '착', ruby: {ja: 'チャク'}},
  {freq: 6, shiin: '짓', ruby: {ja: 'チッ'}},
  {freq: 6, shiin: '졸', ruby: {ja: 'チョル'}},
  {freq: 6, shiin: '왕', ruby: {ja: 'ワン'}},
  {freq: 6, shiin: '염', ruby: {ja: 'ヨム'}},
  {freq: 6, shiin: '슬', ruby: {ja: 'スル'}},
  {freq: 6, shiin: '숨', ruby: {ja: 'スム'}},
  {freq: 6, shiin: '섯', ruby: {ja: 'ソッ'}},
  {freq: 6, shiin: '붙', ruby: {ja: 'プッ'}},
  {freq: 6, shiin: '범', ruby: {ja: 'ポム'}},
  {freq: 6, shiin: '밝', ruby: {ja: 'パルッ'}},
  {freq: 6, shiin: '린', ruby: {ja: 'リン'}},
  {freq: 6, shiin: '뜻', ruby: {ja: 'ットゥッ'}},
  {freq: 6, shiin: '덕', ruby: {ja: 'トク'}},
  {freq: 6, shiin: '늦', ruby: {ja: 'ヌッ'}},
  {freq: 6, shiin: '긴', ruby: {ja: 'キン'}},
  {freq: 6, shiin: '궁', ruby: {ja: 'クン'}},
  {freq: 6, shiin: '겁', ruby: {ja: 'コプ'}},
  {freq: 6, shiin: '객', ruby: {ja: 'ケク'}},
  {freq: 6, shiin: '같', ruby: {ja: 'カッ'}},
  {freq: 5, shiin: '층', ruby: {ja: 'チュン'}},
  {freq: 5, shiin: '총', ruby: {ja: 'チョン'}},
  {freq: 5, shiin: '촌', ruby: {ja: 'チョン'}},
  {freq: 5, shiin: '척', ruby: {ja: 'チョク'}},
  {freq: 5, shiin: '징', ruby: {ja: 'チン'}},
  {freq: 5, shiin: '짐', ruby: {ja: 'チム'}},
  {freq: 5, shiin: '좋', ruby: {ja: 'チョッ'}},
  {freq: 5, shiin: '옆', ruby: {ja: 'ヨプ'}},
  {freq: 5, shiin: '액', ruby: {ja: 'エク'}},
  {freq: 5, shiin: '쓸', ruby: {ja: 'ッスル'}},
  {freq: 5, shiin: '섭', ruby: {ja: 'ソプ'}},
  {freq: 5, shiin: '봉', ruby: {ja: 'ポン'}},
  {freq: 5, shiin: '받', ruby: {ja: 'パッ'}},
  {freq: 5, shiin: '밖', ruby: {ja: 'パク'}},
  {freq: 5, shiin: '묻', ruby: {ja: 'ムッ'}},
  {freq: 5, shiin: '몇', ruby: {ja: 'ミョッ'}},
  {freq: 5, shiin: '령', ruby: {ja: 'リョン'}},
  {freq: 5, shiin: '량', ruby: {ja: 'リャン'}},
  {freq: 5, shiin: '란', ruby: {ja: 'ラン'}},
  {freq: 5, shiin: '똑', ruby: {ja: 'ットク'}},
  {freq: 5, shiin: '떡', ruby: {ja: 'ットク'}},
  {freq: 5, shiin: '땅', ruby: {ja: 'ッタン'}},
  {freq: 5, shiin: '딸', ruby: {ja: 'ッタル'}},
  {freq: 5, shiin: '듯', ruby: {ja: 'トゥッ'}},
  {freq: 5, shiin: '둥', ruby: {ja: 'トゥン'}},
  {freq: 5, shiin: '높', ruby: {ja: 'ノプ'}},
  {freq: 5, shiin: '끊', ruby: {ja: 'ックン'}},
  {freq: 5, shiin: '깔', ruby: {ja: 'ッカル'}},
  {freq: 5, shiin: '갖', ruby: {ja: 'カッ'}},
  {freq: 4, shiin: '흥', ruby: {ja: 'フン'}},
  {freq: 4, shiin: '홍', ruby: {ja: 'ホン'}},
  {freq: 4, shiin: '첫', ruby: {ja: 'チョッ'}},
  {freq: 4, shiin: '찍', ruby: {ja: 'ッチク'}},
  {freq: 4, shiin: '즐', ruby: {ja: 'チュル'}},
  {freq: 4, shiin: '쟁', ruby: {ja: 'チェン'}},
  {freq: 4, shiin: '을', ruby: {ja: 'ウル'}},
  {freq: 4, shiin: '를', ruby: {ja: 'ルル'}},
  {freq: 4, shiin: '율', ruby: {ja: 'ユル'}},
  {freq: 4, shiin: '욱', ruby: {ja: 'ウク'}},
  {freq: 4, shiin: '옷', ruby: {ja: 'オッ'}},
  {freq: 4, shiin: '엉', ruby: {ja: 'オン'}},
  {freq: 4, shiin: '엄', ruby: {ja: 'オム'}},
  {freq: 4, shiin: '앉', ruby: {ja: 'アン'}},
  {freq: 4, shiin: '빗', ruby: {ja: 'ピッ'}},
  {freq: 4, shiin: '볼', ruby: {ja: 'ポル'}},
  {freq: 4, shiin: '볶', ruby: {ja: 'ポク'}},
  {freq: 4, shiin: '몰', ruby: {ja: 'モル'}},
  {freq: 4, shiin: '많', ruby: {ja: 'マン'}},
  {freq: 4, shiin: '랗', ruby: {ja: 'ラッ'}},
  {freq: 4, shiin: '떨', ruby: {ja: 'ットル'}},
  {freq: 4, shiin: '딱', ruby: {ja: 'ッタク'}},
  {freq: 4, shiin: '뒷', ruby: {ja: 'トゥィッ'}},
  {freq: 4, shiin: '넷', ruby: {ja: 'ネッ'}},
  {freq: 4, shiin: '넓', ruby: {ja: 'ノル'}},
  {freq: 4, shiin: '널', ruby: {ja: 'ノル'}},
  {freq: 4, shiin: '냉', ruby: {ja: 'ネン'}},
  {freq: 4, shiin: '끌', ruby: {ja: 'ックル'}},
  {freq: 4, shiin: '꽃', ruby: {ja: 'ッコッ'}},
  {freq: 4, shiin: '꼭', ruby: {ja: 'ッコク'}},
  {freq: 4, shiin: '깊', ruby: {ja: 'キプ'}},
  {freq: 4, shiin: '굳', ruby: {ja: 'クッ'}},
  {freq: 4, shiin: '곡', ruby: {ja: 'コク'}},
  {freq: 4, shiin: '걷', ruby: {ja: 'コッ'}},
  {freq: 4, shiin: '걱', ruby: {ja: 'コク'}},
  {freq: 3, shiin: '획', ruby: {ja: 'フェク'}},
  {freq: 3, shiin: '혹', ruby: {ja: 'ホク'}},
  {freq: 3, shiin: '햇', ruby: {ja: 'ヘッ'}},
  {freq: 3, shiin: '했', ruby: {ja: 'ヘッ'}},
  {freq: 3, shiin: '폭', ruby: {ja: 'ポク'}},
  {freq: 3, shiin: '팬', ruby: {ja: 'ペン'}},
  {freq: 3, shiin: '팩', ruby: {ja: 'ペク'}},
  {freq: 3, shiin: '텔', ruby: {ja: 'テル'}},
  {freq: 3, shiin: '킬', ruby: {ja: 'キル'}},
  {freq: 3, shiin: '클', ruby: {ja: 'クル'}},
  {freq: 3, shiin: '콩', ruby: {ja: 'コン'}},
  {freq: 3, shiin: '컷', ruby: {ja: 'コッ'}},
  {freq: 3, shiin: '컸', ruby: {ja: 'コッ'}},
  {freq: 3, shiin: '컵', ruby: {ja: 'コプ'}},
  {freq: 3, shiin: '컨', ruby: {ja: 'コン'}},
  {freq: 3, shiin: '칼', ruby: {ja: 'カル'}},
  {freq: 3, shiin: '칭', ruby: {ja: 'チン'}},
  {freq: 3, shiin: '칙', ruby: {ja: 'チク'}},
  {freq: 3, shiin: '쫓', ruby: {ja: 'ッチョッ'}},
  {freq: 3, shiin: '즉', ruby: {ja: 'チュク'}},
  {freq: 3, shiin: '젊', ruby: {ja: 'チョルッ'}},
  {freq: 3, shiin: '잎', ruby: {ja: 'イプ'}},
  {freq: 3, shiin: '잊', ruby: {ja: 'イッ'}},
  {freq: 3, shiin: '웬', ruby: {ja: 'ウェン'}},
  {freq: 3, shiin: '왼', ruby: {ja: 'ウェン'}},
  {freq: 3, shiin: '옥', ruby: {ja: 'オク'}},
  {freq: 3, shiin: '옛', ruby: {ja: 'イェッ'}},
  {freq: 3, shiin: '엽', ruby: {ja: 'ヨプ'}},
  {freq: 3, shiin: '앗', ruby: {ja: 'アッ'}},
  {freq: 3, shiin: '았', ruby: {ja: 'アッ'}},
  {freq: 3, shiin: '암', ruby: {ja: 'アム'}},
  {freq: 3, shiin: '않', ruby: {ja: 'アンッ'}},
  {freq: 3, shiin: '씻', ruby: {ja: 'ッシッ'}},
  {freq: 3, shiin: '씩', ruby: {ja: 'ッシク'}},
  {freq: 3, shiin: '씀', ruby: {ja: 'ッスム'}},
  {freq: 3, shiin: '썩', ruby: {ja: 'ッソク'}},
  {freq: 3, shiin: '쌍', ruby: {ja: 'ッサン'}},
  {freq: 3, shiin: '싶', ruby: {ja: 'シプ'}},
  {freq: 3, shiin: '싱', ruby: {ja: 'シン'}},
  {freq: 3, shiin: '싫', ruby: {ja: 'シルッ'}},
  {freq: 3, shiin: '슴', ruby: {ja: 'スム'}},
  {freq: 3, shiin: '쉽', ruby: {ja: 'スィプ'}},
  {freq: 3, shiin: '솔', ruby: {ja: 'ソル'}},
  {freq: 3, shiin: '셋', ruby: {ja: 'セッ'}},
  {freq: 3, shiin: '센', ruby: {ja: 'セン'}},
  {freq: 3, shiin: '빌', ruby: {ja: 'ピル'}},
  {freq: 3, shiin: '벽', ruby: {ja: 'ピョク'}},
  {freq: 3, shiin: '믿', ruby: {ja: 'ミッ'}},
  {freq: 3, shiin: '묵', ruby: {ja: 'ムク'}},
  {freq: 3, shiin: '멍', ruby: {ja: 'モン'}},
  {freq: 3, shiin: '멋', ruby: {ja: 'モッ'}},
  {freq: 3, shiin: '멀', ruby: {ja: 'モル'}},
  {freq: 3, shiin: '맡', ruby: {ja: 'マッ'}},
  {freq: 3, shiin: '릇', ruby: {ja: 'ルッ'}},
  {freq: 3, shiin: '렵', ruby: {ja: 'リョプ'}},
  {freq: 3, shiin: '럼', ruby: {ja: 'ロム'}},
  {freq: 3, shiin: '랫', ruby: {ja: 'レッ'}},
  {freq: 3, shiin: '램', ruby: {ja: 'レム'}},
  {freq: 3, shiin: '랜', ruby: {ja: 'レン'}},
  {freq: 3, shiin: '뚱', ruby: {ja: 'ットゥン'}},
  {freq: 3, shiin: '듬', ruby: {ja: 'トゥム'}},
  {freq: 3, shiin: '든', ruby: {ja: 'トゥン'}},
  {freq: 3, shiin: '닷', ruby: {ja: 'タッ'}},
  {freq: 3, shiin: '닭', ruby: {ja: 'タク'}},
  {freq: 3, shiin: '닫', ruby: {ja: 'タッ'}},
  {freq: 3, shiin: '놈', ruby: {ja: 'ノム'}},
  {freq: 3, shiin: '녕', ruby: {ja: 'ニョン'}},
  {freq: 3, shiin: '녁', ruby: {ja: 'ニョク'}},
  {freq: 3, shiin: '넉', ruby: {ja: 'ノク'}},
  {freq: 3, shiin: '낚', ruby: {ja: 'ナク'}},
  {freq: 3, shiin: '끗', ruby: {ja: 'ックッ'}},
  {freq: 3, shiin: '꿈', ruby: {ja: 'ックム'}},
  {freq: 3, shiin: '꼼', ruby: {ja: 'ッコム'}},
  {freq: 3, shiin: '껏', ruby: {ja: 'ッコッ'}},
  {freq: 3, shiin: '깝', ruby: {ja: 'ッカプ'}},
  {freq: 3, shiin: '곤', ruby: {ja: 'コン'}},
  {freq: 3, shiin: '값', ruby: {ja: 'カプ'}},
  {freq: 2, shiin: '흑', ruby: {ja: 'フク'}},
  {freq: 2, shiin: '훈', ruby: {ja: 'フン'}},
  {freq: 2, shiin: '황', ruby: {ja: 'ホヮン'}},
  {freq: 2, shiin: '홀', ruby: {ja: 'ホル'}},
  {freq: 2, shiin: '협', ruby: {ja: 'ヒョプ'}},
  {freq: 2, shiin: '햄', ruby: {ja: 'ヘム'}},
  {freq: 2, shiin: '핸', ruby: {ja: 'ヘン'}},
  {freq: 2, shiin: '핵', ruby: {ja: 'ヘク'}},
  {freq: 2, shiin: '핑', ruby: {ja: 'ピン'}},
  {freq: 2, shiin: '픽', ruby: {ja: 'ピク'}},
  {freq: 2, shiin: '픔', ruby: {ja: 'プム'}},
  {freq: 2, shiin: '플', ruby: {ja: 'プル'}},
  {freq: 2, shiin: '톱', ruby: {ja: 'トプ'}},
  {freq: 2, shiin: '톤', ruby: {ja: 'トン'}},
  {freq: 2, shiin: '털', ruby: {ja: 'トル'}},
  {freq: 2, shiin: '턱', ruby: {ja: 'トク'}},
  {freq: 2, shiin: '탄', ruby: {ja: 'タン'}},
  {freq: 2, shiin: '큼', ruby: {ja: 'クム'}},
  {freq: 2, shiin: '콜', ruby: {ja: 'コル'}},
  {freq: 2, shiin: '켓', ruby: {ja: 'ケッ'}},
  {freq: 2, shiin: '컴', ruby: {ja: 'コム'}},
  {freq: 2, shiin: '캠', ruby: {ja: 'ケム'}},
  {freq: 2, shiin: '캄', ruby: {ja: 'カム'}},
  {freq: 2, shiin: '측', ruby: {ja: 'チュク'}},
  {freq: 2, shiin: '춧', ruby: {ja: 'チュッ'}},
  {freq: 2, shiin: '춤', ruby: {ja: 'チュム'}},
  {freq: 2, shiin: '춘', ruby: {ja: 'チュン'}},
  {freq: 2, shiin: '찮', ruby: {ja: 'チャン'}},
  {freq: 2, shiin: '찢', ruby: {ja: 'ッチッ'}},
  {freq: 2, shiin: '쩔', ruby: {ja: 'ッチョル'}},
  {freq: 2, shiin: '짧', ruby: {ja: 'ッチャルプ'}},
  {freq: 2, shiin: '좁', ruby: {ja: 'チョプ'}},
  {freq: 2, shiin: '젖', ruby: {ja: 'チョッ'}},
  {freq: 2, shiin: '젓', ruby: {ja: 'チョッ'}},
  {freq: 2, shiin: '잣', ruby: {ja: 'チャッ'}},
  {freq: 2, shiin: '잇', ruby: {ja: 'イッ'}},
  {freq: 2, shiin: '있', ruby: {ja: 'イッ'}},
  {freq: 2, shiin: '잃', ruby: {ja: 'イル'}},
  {freq: 2, shiin: '읽', ruby: {ja: 'イルク'}},
  {freq: 2, shiin: '윗', ruby: {ja: 'ウィッ'}},
  {freq: 2, shiin: '엔', ruby: {ja: 'エン'}},
  {freq: 2, shiin: '엇', ruby: {ja: 'オッ'}},
  {freq: 2, shiin: '었', ruby: {ja: 'オッ'}},
  {freq: 2, shiin: '얻', ruby: {ja: 'オッ'}},
  {freq: 2, shiin: '쏟', ruby: {ja: 'ッソッ'}},
  {freq: 2, shiin: '썰', ruby: {ja: 'ッソル'}},
  {freq: 2, shiin: '쌓', ruby: {ja: 'ッサッ'}},
  {freq: 2, shiin: '싹', ruby: {ja: 'ッサク'}},
  {freq: 2, shiin: '숭', ruby: {ja: 'スン'}},
  {freq: 2, shiin: '솥', ruby: {ja: 'ソッ'}},
  {freq: 2, shiin: '솜', ruby: {ja: 'ソム'}},
  {freq: 2, shiin: '션', ruby: {ja: 'ション'}},
  {freq: 2, shiin: '섞', ruby: {ja: 'ソク'}},
  {freq: 2, shiin: '삶', ruby: {ja: 'サルム'}},
  {freq: 2, shiin: '뽑', ruby: {ja: 'ッポプ'}},
  {freq: 2, shiin: '뻔', ruby: {ja: 'ッポン'}},
  {freq: 2, shiin: '빵', ruby: {ja: 'ッパン'}},
  {freq: 2, shiin: '블', ruby: {ja: 'プル'}},
  {freq: 2, shiin: '붓', ruby: {ja: 'プッ'}},
  {freq: 2, shiin: '벗', ruby: {ja: 'ポッ'}},
  {freq: 2, shiin: '밭', ruby: {ja: 'パッ'}},
  {freq: 2, shiin: '밑', ruby: {ja: 'ミッ'}},
  {freq: 2, shiin: '밉', ruby: {ja: 'ミプ'}},
  {freq: 2, shiin: '뭇', ruby: {ja: 'ムッ'}},
  {freq: 2, shiin: '묶', ruby: {ja: 'ムク'}},
  {freq: 2, shiin: '먼', ruby: {ja: 'モン'}},
  {freq: 2, shiin: '맣', ruby: {ja: 'マッ'}},
  {freq: 2, shiin: '맘', ruby: {ja: 'マム'}},
  {freq: 2, shiin: '릿', ruby: {ja: 'リッ'}},
  {freq: 2, shiin: '릭', ruby: {ja: 'リク'}},
  {freq: 2, shiin: '률', ruby: {ja: 'リュル'}},
  {freq: 2, shiin: '롯', ruby: {ja: 'ロッ'}},
  {freq: 2, shiin: '렌', ruby: {ja: 'レン'}},
  {freq: 2, shiin: '렁', ruby: {ja: 'ロン'}},
  {freq: 2, shiin: '랍', ruby: {ja: 'ラプ'}},
  {freq: 2, shiin: '딪', ruby: {ja: 'ティッ'}},
  {freq: 2, shiin: '듣', ruby: {ja: 'トゥッ'}},
  {freq: 2, shiin: '된', ruby: {ja: 'トゥェン'}},
  {freq: 2, shiin: '돈', ruby: {ja: 'トン'}},
  {freq: 2, shiin: '덮', ruby: {ja: 'トプ'}},
  {freq: 2, shiin: '덩', ruby: {ja: 'トン'}},
  {freq: 2, shiin: '덧', ruby: {ja: 'トッ'}},
  {freq: 2, shiin: '덥', ruby: {ja: 'トプ'}},
  {freq: 2, shiin: '덜', ruby: {ja: 'トル'}},
  {freq: 2, shiin: '던', ruby: {ja: 'トン'}},
  {freq: 2, shiin: '댁', ruby: {ja: 'テク'}},
  {freq: 2, shiin: '닐', ruby: {ja: 'ニル'}},
  {freq: 2, shiin: '넣', ruby: {ja: 'ノッ'}},
  {freq: 2, shiin: '냥', ruby: {ja: 'ニャン'}},
  {freq: 2, shiin: '냄', ruby: {ja: 'ネム'}},
  {freq: 2, shiin: '낫', ruby: {ja: 'ナッ'}},
  {freq: 2, shiin: '낙', ruby: {ja: 'ナク'}},
  {freq: 2, shiin: '끔', ruby: {ja: 'ックム'}},
  {freq: 2, shiin: '끓', ruby: {ja: 'ックル'}},
  {freq: 2, shiin: '꾼', ruby: {ja: 'ックン'}},
  {freq: 2, shiin: '꼽', ruby: {ja: 'ッコプ'}},
  {freq: 2, shiin: '껍', ruby: {ja: 'ッコプ'}},
  {freq: 2, shiin: '깥', ruby: {ja: 'ッカッ'}},
  {freq: 2, shiin: '깜', ruby: {ja: 'ッカム'}},
  {freq: 2, shiin: '깐', ruby: {ja: 'ッカン'}},
  {freq: 2, shiin: '균', ruby: {ja: 'キュン'}},
  {freq: 2, shiin: '굽', ruby: {ja: 'クプ'}},
  {freq: 2, shiin: '굉', ruby: {ja: 'クェン'}},
  {freq: 2, shiin: '괜', ruby: {ja: 'クェン'}},
  {freq: 2, shiin: '곱', ruby: {ja: 'コプ'}},
  {freq: 2, shiin: '겹', ruby: {ja: 'キョプ'}},
  {freq: 1, shiin: '흰', ruby: {ja: 'フィン'}},
  {freq: 1, shiin: '흩', ruby: {ja: 'フッ'}},
  {freq: 1, shiin: '흡', ruby: {ja: 'フプ'}},
  {freq: 1, shiin: '흙', ruby: {ja: 'フク'}},
  {freq: 1, shiin: '흉', ruby: {ja: 'ヒュン'}},
  {freq: 1, shiin: '훨', ruby: {ja: 'フォル'}},
  {freq: 1, shiin: '훔', ruby: {ja: 'フム'}},
  {freq: 1, shiin: '훌', ruby: {ja: 'フル'}},
  {freq: 1, shiin: '횡', ruby: {ja: 'フェン'}},
  {freq: 1, shiin: '횟', ruby: {ja: 'フェッ'}},
  {freq: 1, shiin: '홉', ruby: {ja: 'ホプ'}},
  {freq: 1, shiin: '홈', ruby: {ja: 'ホム'}},
  {freq: 1, shiin: '혈', ruby: {ja: 'ヒョル'}},
  {freq: 1, shiin: '헬', ruby: {ja: 'ヘル'}},
  {freq: 1, shiin: '헌', ruby: {ja: 'ヒョン'}},
  {freq: 1, shiin: '핏', ruby: {ja: 'ピッ'}},
  {freq: 1, shiin: '푹', ruby: {ja: 'プク'}},
  {freq: 1, shiin: '폰', ruby: {ja: 'ポン'}},
  {freq: 1, shiin: '펼', ruby: {ja: 'ピョル'}},
  {freq: 1, shiin: '펜', ruby: {ja: 'ペン'}},
  {freq: 1, shiin: '퍽', ruby: {ja: 'ポク'}},
  {freq: 1, shiin: '팝', ruby: {ja: 'パプ'}},
  {freq: 1, shiin: '팎', ruby: {ja: 'パク'}},
  {freq: 1, shiin: '팅', ruby: {ja: 'ティン'}},
  {freq: 1, shiin: '팀', ruby: {ja: 'ティム'}},
  {freq: 1, shiin: '틱', ruby: {ja: 'ティク'}},
  {freq: 1, shiin: '틈', ruby: {ja: 'トゥム'}},
  {freq: 1, shiin: '튿', ruby: {ja: 'トゥッ'}},
  {freq: 1, shiin: '퉁', ruby: {ja: 'トゥン'}},
  {freq: 1, shiin: '툼', ruby: {ja: 'トゥム'}},
  {freq: 1, shiin: '툴', ruby: {ja: 'トゥル'}},
  {freq: 1, shiin: '톨', ruby: {ja: 'トル'}},
  {freq: 1, shiin: '템', ruby: {ja: 'テム'}},
  {freq: 1, shiin: '텍', ruby: {ja: 'テク'}},
  {freq: 1, shiin: '텅', ruby: {ja: 'トン'}},
  {freq: 1, shiin: '턴', ruby: {ja: 'トン'}},
  {freq: 1, shiin: '탤', ruby: {ja: 'テル'}},
  {freq: 1, shiin: '탓', ruby: {ja: 'タッ'}},
  {freq: 1, shiin: '탔', ruby: {ja: 'タッ'}},
  {freq: 1, shiin: '탑', ruby: {ja: 'タプ'}},
  {freq: 1, shiin: '탈', ruby: {ja: 'タル'}},
  {freq: 1, shiin: '콤', ruby: {ja: 'コム'}},
  {freq: 1, shiin: '콘', ruby: {ja: 'コン'}},
  {freq: 1, shiin: '컬', ruby: {ja: 'コル'}},
  {freq: 1, shiin: '칸', ruby: {ja: 'カン'}},
  {freq: 1, shiin: '칫', ruby: {ja: 'チッ'}},
  {freq: 1, shiin: '츰', ruby: {ja: 'チュム'}},
  {freq: 1, shiin: '춥', ruby: {ja: 'チュプ'}},
  {freq: 1, shiin: '촬', ruby: {ja: 'チョヮル'}},
  {freq: 1, shiin: '촛', ruby: {ja: 'チョッ'}},
  {freq: 1, shiin: '촉', ruby: {ja: 'チョク'}},
  {freq: 1, shiin: '첩', ruby: {ja: 'チョプ'}},
  {freq: 1, shiin: '챙', ruby: {ja: 'チェン'}},
  {freq: 1, shiin: '챔', ruby: {ja: 'チェム'}},
  {freq: 1, shiin: '찻', ruby: {ja: 'チャッ'}},
  {freq: 1, shiin: '쭉', ruby: {ja: 'ッチュク'}},
  {freq: 1, shiin: '쩜', ruby: {ja: 'ッチョム'}},
  {freq: 1, shiin: '쩐', ruby: {ja: 'ッチョン'}},
  {freq: 1, shiin: '쩍', ruby: {ja: 'ッチョク'}},
  {freq: 1, shiin: '쨌', ruby: {ja: 'ッチェッ'}},
  {freq: 1, shiin: '짚', ruby: {ja: 'チプ'}},
  {freq: 1, shiin: '짙', ruby: {ja: 'チッ'}},
  {freq: 1, shiin: '즘', ruby: {ja: 'チム'}},
  {freq: 1, shiin: '즌', ruby: {ja: 'チュン'}},
  {freq: 1, shiin: '줍', ruby: {ja: 'チュプ'}},
  {freq: 1, shiin: '줌', ruby: {ja: 'チュム'}},
  {freq: 1, shiin: '좀', ruby: {ja: 'チョム'}},
  {freq: 1, shiin: '젯', ruby: {ja: 'チェッ'}},
  {freq: 1, shiin: '젠', ruby: {ja: 'チェン'}},
  {freq: 1, shiin: '잦', ruby: {ja: 'チャッ'}},
  {freq: 1, shiin: '잖', ruby: {ja: 'チャン'}},
  {freq: 1, shiin: '윽', ruby: {ja: 'ウク'}},
  {freq: 1, shiin: '웅', ruby: {ja: 'ウン'}},
  {freq: 1, shiin: '왠', ruby: {ja: 'ウェン'}},
  {freq: 1, shiin: '옹', ruby: {ja: 'オン'}},
  {freq: 1, shiin: '옳', ruby: {ja: 'オル'}},
  {freq: 1, shiin: '옮', ruby: {ja: 'オルム'}},
  {freq: 1, shiin: '엿', ruby: {ja: 'ヨッ'}},
  {freq: 1, shiin: '였', ruby: {ja: 'ヨッ'}},
  {freq: 1, shiin: '엷', ruby: {ja: 'ヨル'}},
  {freq: 1, shiin: '엘', ruby: {ja: 'エル'}},
  {freq: 1, shiin: '엎', ruby: {ja: 'オプ'}},
  {freq: 1, shiin: '엌', ruby: {ja: 'オッ'}},
  {freq: 1, shiin: '엊', ruby: {ja: 'オッ'}},
  {freq: 1, shiin: '얹', ruby: {ja: 'オン'}},
  {freq: 1, shiin: '얗', ruby: {ja: 'ヤッ'}},
  {freq: 1, shiin: '얕', ruby: {ja: 'ヤッ'}},
  {freq: 1, shiin: '얇', ruby: {ja: 'ヤルッ'}},
  {freq: 1, shiin: '얄', ruby: {ja: 'ヤル'}},
  {freq: 1, shiin: '얀', ruby: {ja: 'ヤン'}},
  {freq: 1, shiin: '앨', ruby: {ja: 'エル'}},
  {freq: 1, shiin: '앙', ruby: {ja: 'アン'}},
  {freq: 1, shiin: '압', ruby: {ja: 'アプ'}},
  {freq: 1, shiin: '앓', ruby: {ja: 'アルッ'}},
  {freq: 1, shiin: '씹', ruby: {ja: 'ッシプ'}},
  {freq: 1, shiin: '씬', ruby: {ja: 'ッシン'}},
  {freq: 1, shiin: '쓴', ruby: {ja: 'ッスン'}},
  {freq: 1, shiin: '썹', ruby: {ja: 'ッソプ'}},
  {freq: 1, shiin: '쌀', ruby: {ja: 'ッサル'}},
  {freq: 1, shiin: '싼', ruby: {ja: 'ッサン'}},
  {freq: 1, shiin: '싯', ruby: {ja: 'シッ'}},
  {freq: 1, shiin: '싣', ruby: {ja: 'シッ'}},
  {freq: 1, shiin: '슷', ruby: {ja: 'スッ'}},
  {freq: 1, shiin: '슨', ruby: {ja: 'スン'}},
  {freq: 1, shiin: '쉰', ruby: {ja: 'スィン'}},
  {freq: 1, shiin: '숲', ruby: {ja: 'スプ'}},
  {freq: 1, shiin: '숫', ruby: {ja: 'スッ'}},
  {freq: 1, shiin: '숟', ruby: {ja: 'スッ'}},
  {freq: 1, shiin: '솟', ruby: {ja: 'ソッ'}},
  {freq: 1, shiin: '셈', ruby: {ja: 'セム'}},
  {freq: 1, shiin: '섹', ruby: {ja: 'セク'}},
  {freq: 1, shiin: '섬', ruby: {ja: 'ソム'}},
  {freq: 1, shiin: '샌', ruby: {ja: 'セン'}},
  {freq: 1, shiin: '삿', ruby: {ja: 'サッ'}},
  {freq: 1, shiin: '샀', ruby: {ja: 'サッ'}},
  {freq: 1, shiin: '삭', ruby: {ja: 'サク'}},
  {freq: 1, shiin: '쁨', ruby: {ja: 'ップム'}},
  {freq: 1, shiin: '뿐', ruby: {ja: 'ップン'}},
  {freq: 1, shiin: '뼉', ruby: {ja: 'ッピョク'}},
  {freq: 1, shiin: '뻗', ruby: {ja: 'ッピョッ'}},
  {freq: 1, shiin: '뺨', ruby: {ja: 'ッピャム'}},
  {freq: 1, shiin: '뺏', ruby: {ja: 'ッペッ'}},
  {freq: 1, shiin: '빡', ruby: {ja: 'ッパク'}},
  {freq: 1, shiin: '빚', ruby: {ja: 'ピッ'}},
  {freq: 1, shiin: '빔', ruby: {ja: 'ピム'}},
  {freq: 1, shiin: '븐', ruby: {ja: 'プン'}},
  {freq: 1, shiin: '붕', ruby: {ja: 'プン'}},
  {freq: 1, shiin: '붐', ruby: {ja: 'プム'}},
  {freq: 1, shiin: '붉', ruby: {ja: 'プゥル'}},
  {freq: 1, shiin: '뵙', ruby: {ja: 'ポェプ'}},
  {freq: 1, shiin: '봇', ruby: {ja: 'ポッ'}},
  {freq: 1, shiin: '봄', ruby: {ja: 'ポム'}},
  {freq: 1, shiin: '볕', ruby: {ja: 'ピョッ'}},
  {freq: 1, shiin: '볍', ruby: {ja: 'ピョプ'}},
  {freq: 1, shiin: '벨', ruby: {ja: 'ペル'}},
  {freq: 1, shiin: '벤', ruby: {ja: 'ペン'}},
  {freq: 1, shiin: '뱉', ruby: {ja: 'ペッ'}},
  {freq: 1, shiin: '뱃', ruby: {ja: 'ペッ'}},
  {freq: 1, shiin: '뱀', ruby: {ja: 'ペム'}},
  {freq: 1, shiin: '밟', ruby: {ja: 'パルッ'}},
  {freq: 1, shiin: '및', ruby: {ja: 'ミッ'}},
  {freq: 1, shiin: '밌', ruby: {ja: 'ミッ'}},
  {freq: 0, shiin: '뭔', ruby: {ja: 'ムォン'}},
  {freq: 1, shiin: '뭣', ruby: {ja: 'ムォッ'}},
  {freq: 1, shiin: '뭘', ruby: {ja: 'ムォル'}},
  {freq: 1, shiin: '뭄', ruby: {ja: 'ムム'}},
  {freq: 1, shiin: '몽', ruby: {ja: 'モン'}},
  {freq: 1, shiin: '몹', ruby: {ja: 'モプ'}},
  {freq: 1, shiin: '몬', ruby: {ja: 'モン'}},
  {freq: 1, shiin: '멸', ruby: {ja: 'ミョル'}},
  {freq: 1, shiin: '멩', ruby: {ja: 'メン'}},
  {freq: 1, shiin: '멘', ruby: {ja: 'メン'}},
  {freq: 1, shiin: '멎', ruby: {ja: 'モッ'}},
  {freq: 1, shiin: '멈', ruby: {ja: 'モム'}},
  {freq: 1, shiin: '맺', ruby: {ja: 'メッ'}},
  {freq: 1, shiin: '맵', ruby: {ja: 'メプ'}},
  {freq: 1, shiin: '맨', ruby: {ja: 'メン'}},
  {freq: 1, shiin: '맥', ruby: {ja: 'メク'}},
  {freq: 1, shiin: '맙', ruby: {ja: 'マプ'}},
  {freq: 1, shiin: '맑', ruby: {ja: 'マクッ'}},
  {freq: 1, shiin: '링', ruby: {ja: 'リン'}},
  {freq: 1, shiin: '릎', ruby: {ja: 'ルプ'}},
  {freq: 1, shiin: '륭', ruby: {ja: 'リュン'}},
  {freq: 1, shiin: '륙', ruby: {ja: 'リュク'}},
  {freq: 1, shiin: '룻', ruby: {ja: 'ルッ'}},
  {freq: 1, shiin: '룹', ruby: {ja: 'ルプ'}},
  {freq: 1, shiin: '룩', ruby: {ja: 'ルク'}},
  {freq: 1, shiin: '롬', ruby: {ja: 'ロム'}},
  {freq: 1, shiin: '렬', ruby: {ja: 'リョル'}},
  {freq: 1, shiin: '렉', ruby: {ja: 'レク'}},
  {freq: 1, shiin: '럿', ruby: {ja: 'ロッ'}},
  {freq: 1, shiin: '럴', ruby: {ja: 'ロル'}},
  {freq: 1, shiin: '럭', ruby: {ja: 'ロク'}},
  {freq: 1, shiin: '략', ruby: {ja: 'リャク'}},
  {freq: 1, shiin: '뜰', ruby: {ja: 'ットゥル'}},
  {freq: 1, shiin: '뜯', ruby: {ja: 'ットゥッ'}},
  {freq: 1, shiin: '뜩', ruby: {ja: 'ットゥク'}},
  {freq: 1, shiin: '뚫', ruby: {ja: 'ットゥルッ'}},
  {freq: 1, shiin: '떻', ruby: {ja: 'ットゥッ'}},
  {freq: 1, shiin: '떤', ruby: {ja: 'ットゥン'}},
  {freq: 1, shiin: '땜', ruby: {ja: 'ッテム'}},
  {freq: 1, shiin: '땀', ruby: {ja: 'ッタム'}},
  {freq: 1, shiin: '딴', ruby: {ja: 'ッタン'}},
  {freq: 1, shiin: '딩', ruby: {ja: 'ティン'}},
  {freq: 1, shiin: '듭', ruby: {ja: 'トゥプ'}},
  {freq: 1, shiin: '둡', ruby: {ja: 'トゥプ'}},
  {freq: 1, shiin: '둠', ruby: {ja: 'トゥム'}},
  {freq: 1, shiin: '둑', ruby: {ja: 'トゥク'}},
  {freq: 1, shiin: '돗', ruby: {ja: 'トッ'}},
  {freq: 1, shiin: '돕', ruby: {ja: 'トプ'}},
  {freq: 1, shiin: '델', ruby: {ja: 'テル'}},
  {freq: 1, shiin: '덤', ruby: {ja: 'トム'}},
  {freq: 1, shiin: '덟', ruby: {ja: 'トルッ'}},
  {freq: 1, shiin: '댓', ruby: {ja: 'テッ'}},
  {freq: 1, shiin: '댐', ruby: {ja: 'テム'}},
  {freq: 1, shiin: '닿', ruby: {ja: 'タッ'}},
  {freq: 1, shiin: '닮', ruby: {ja: 'タルム'}},
  {freq: 1, shiin: '닦', ruby: {ja: 'タク'}},
  {freq: 1, shiin: '늙', ruby: {ja: 'ヌルッ'}},
  {freq: 1, shiin: '는', ruby: {ja: 'ヌン'}},
  {freq: 1, shiin: '늑', ruby: {ja: 'ヌク'}},
  {freq: 1, shiin: '늄', ruby: {ja: 'ニュム'}},
  {freq: 1, shiin: '눕', ruby: {ja: 'ヌプ'}},
  {freq: 1, shiin: '넥', ruby: {ja: 'ネク'}},
  {freq: 1, shiin: '넌', ruby: {ja: 'ノン'}},
  {freq: 1, shiin: '냇', ruby: {ja: 'ネッ'}},
  {freq: 1, shiin: '낳', ruby: {ja: 'ナッ'}},
  {freq: 1, shiin: '낱', ruby: {ja: 'ナッ'}},
  {freq: 1, shiin: '낯', ruby: {ja: 'ナッ'}},
  {freq: 1, shiin: '낭', ruby: {ja: 'ナン'}},
  {freq: 1, shiin: '납', ruby: {ja: 'ナプ'}},
  {freq: 1, shiin: '낡', ruby: {ja: 'ナルッ'}},
  {freq: 1, shiin: '낌', ruby: {ja: 'ッキム'}},
  {freq: 1, shiin: '끈', ruby: {ja: 'ックン'}},
  {freq: 1, shiin: '꿀', ruby: {ja: 'ックル'}},
  {freq: 1, shiin: '꽉', ruby: {ja: 'ッコヮク'}},
  {freq: 1, shiin: '꽂', ruby: {ja: 'ッコッ'}},
  {freq: 1, shiin: '꼴', ruby: {ja: 'ッコル'}},
  {freq: 1, shiin: '껑', ruby: {ja: 'ッコン'}},
  {freq: 1, shiin: '껌', ruby: {ja: 'ッコム'}},
  {freq: 1, shiin: '꺾', ruby: {ja: 'ッコク'}},
  {freq: 1, shiin: '깡', ruby: {ja: 'ッカン'}},
  {freq: 1, shiin: '깎', ruby: {ja: 'ッカク'}},
  {freq: 1, shiin: '깍', ruby: {ja: 'ッカク'}},
  {freq: 1, shiin: '깅', ruby: {ja: 'キン'}},
  {freq: 1, shiin: '긍', ruby: {ja: 'クン'}},
  {freq: 1, shiin: '긋', ruby: {ja: 'クッ'}},
  {freq: 1, shiin: '긁', ruby: {ja: 'ククッ'}},
  {freq: 1, shiin: '귤', ruby: {ja: 'キュル'}},
  {freq: 1, shiin: '귓', ruby: {ja: 'クィッ'}},
  {freq: 1, shiin: '굶', ruby: {ja: 'クムッ'}},
  {freq: 1, shiin: '굵', ruby: {ja: 'クルッ'}},
  {freq: 1, shiin: '굴', ruby: {ja: 'クル'}},
  {freq: 1, shiin: '곰', ruby: {ja: 'コム'}},
  {freq: 1, shiin: '곁', ruby: {ja: 'キョッ'}},
  {freq: 1, shiin: '겪', ruby: {ja: 'キョク'}},
  {freq: 1, shiin: '겉', ruby: {ja: 'コッ'}},
  {freq: 1, shiin: '걀', ruby: {ja: 'キャル'}},
  {freq: 1, shiin: '갛', ruby: {ja: 'カッ'}},
  {freq: 1, shiin: '갚', ruby: {ja: 'カプ'}},
  {freq: 1, shiin: '갓', ruby: {ja: 'カッ'}},
  {freq: 1, shiin: '갔', ruby: {ja: 'カッ'}},
  {freq: 1, shiin: '갇', ruby: {ja: 'カッ'}},
  {freq: 1, shiin: '빴', ruby: {ja: 'ッパッ'}},
  {freq: 1, shiin: '봤', ruby: {ja: 'ポヮッ'}},
  {freq: 1, shiin: '됐', ruby: {ja: 'トゥェッ'}},
  {freq: 1, shiin: '썼', ruby: {ja: 'ッソッ'}},
  {freq: 1, shiin: '녔', ruby: {ja: 'ニョッ'}},
  {freq: 1, shiin: '렸', ruby: {ja: 'リョッ'}},
  {freq: 1, shiin: '랐', ruby: {ja: 'ラッ'}},
  {freq: 1, shiin: '웠', ruby: {ja: 'ウォッ'}},
  {freq: 1, shiin: '쳤', ruby: {ja: 'チョッ'}},
  {freq: 1, shiin: '셨', ruby: {ja: 'ショッ'}},
  {freq: 1, shiin: '깼', ruby: {ja: 'ッケッ'}},
  {freq: 1, shiin: '왔', ruby: {ja: 'ワッ'}},
  {freq: 1, shiin: '났', ruby: {ja: 'ナッ'}},
  {freq: 1, shiin: '졌', ruby: {ja: 'チョッ'}},
  {freq: 1, shiin: '팠', ruby: {ja: 'パッ'}},
  {freq: 1, shiin: '뻤', ruby: {ja: 'ッポッ'}},
  {freq: 1, shiin: '뺐', ruby: {ja: 'ッペッ'}},
  {freq: 1, shiin: '뜬', ruby: {ja: 'ットン'}},
  {freq: 1, shiin: '쁜', ruby: {ja: 'ッポン'}},
  {freq: 1, shiin: '칩', ruby: {ja: 'チプ'}},
  {freq: 1, shiin: '됩', ruby: {ja: 'トゥェプ'}},
  {freq: 1, shiin: '둔', ruby: {ja: 'トゥン'}},
  {freq: 1, shiin: '꽁', ruby: {ja: 'ッコン'}},
  {freq: 1, shiin: '섰', ruby: {ja: 'ソッ'}},
  {freq: 0, shiin: '뿍', ruby: {ja: 'ップク'}},
  {freq: 0, shiin: '짖', ruby: {ja: 'チッ'}},
  {freq: 0, shiin: '늠', ruby: {ja: 'ヌム'}},
  {freq: 0, shiin: '냈', ruby: {ja: 'ネッ'}},
  {freq: 0, shiin: '잉', ruby: {ja: 'イン'}},
  {freq: 0, shiin: '땄', ruby: {ja: 'ッタッ'}},
  {freq: 0, shiin: '캔', ruby: {ja: 'ケン'}},
  {freq: 0, shiin: '낼', ruby: {ja: 'ネル'}},
  {freq: 0, shiin: '융', ruby: {ja: 'ユン'}},
  {freq: 0, shiin: '힌', ruby: {ja: 'ヒン'}},
  {freq: 0, shiin: '핀', ruby: {ja: 'ピン'}},
  {freq: 0, shiin: '겅', ruby: {ja: 'コン'}},
  {freq: 0, shiin: '뿔', ruby: {ja: 'ップル'}},
  {freq: 1, shiin: '렀', ruby: {ja: 'ロッ'}},
  {freq: 0, shiin: '겼', ruby: {ja: 'キョッ'}},
  {freq: 0, shiin: '꺽', ruby: {ja: 'ッコク'}},
  {freq: 0, shiin: '릴', ruby: {ja: 'リル'}},
  {freq: 0, shiin: '킨', ruby: {ja: 'キン'}},
  {freq: 0, shiin: '겠', ruby: {ja: 'ケッ'}},
  {freq: 4, shiin: '넋', ruby: {ja: 'ノッ'}},
  {freq: 0, shiin: '쪘', ruby: {ja: 'ッチョッ'}},
  {freq: 0, shiin: '찼', ruby: {ja: 'チャッ'}},
  {freq: 0, shiin: '숯', ruby: {ja: 'スッ'}},
  {freq: 0, shiin: '룰', ruby: {ja: 'ルル'}},
  {freq: 0, shiin: '즙', ruby: {ja: 'チュプ'}},
  {freq: 0, shiin: '셌', ruby: {ja: 'セッ'}},
  {freq: 0, shiin: '맏', ruby: {ja: 'マッ'}},
  {freq: 0, shiin: '힙', ruby: {ja: 'ヒプ'}},
  {freq: 0, shiin: '몫', ruby: {ja: 'モク'}},
  {freq: 0, shiin: '췄', ruby: {ja: 'チュォッ'}},
  {freq: 0, shiin: '쏜', ruby: {ja: 'ッソン'}},
  {freq: 0, shiin: '찔', ruby: {ja: 'ッチル'}},
  {freq: 0, shiin: '빈', ruby: {ja: 'ピン'}},
  {freq: 0, shiin: '샘', ruby: {ja: 'セム'}},
  {freq: 0, shiin: '펑', ruby: {ja: 'ポン'}},
  {freq: 0, shiin: '푼', ruby: {ja: 'プン'}},
  {freq: 0, shiin: '읊', ruby: {ja: 'ウルプ'}},
  {freq: 0, shiin: '혔', ruby: {ja: 'ヒョッ'}},
  {freq: 0, shiin: '잤', ruby: {ja: 'チャッ'}},
  {freq: 0, shiin: '줬', ruby: {ja: 'チュォッ'}},
  {freq: 0, shiin: '펠', ruby: {ja: 'ペル'}},
  {freq: 0, shiin: '될', ruby: {ja: 'トゥェル'}},
  {freq: 0, shiin: '뚝', ruby: {ja: 'ットゥク'}},
  {freq: 0, shiin: '눌', ruby: {ja: 'ヌル'}},
  {freq: 0, shiin: '킵', ruby: {ja: 'キプ'}},
  {freq: 0, shiin: '툽', ruby: {ja: 'トゥプ'}},
  {freq: 0, shiin: '뀝', ruby: {ja: 'ックィプ'}},
  {freq: 0, shiin: '돋', ruby: {ja: 'トッ'}},
  {freq: 0, shiin: '롱', ruby: {ja: 'ロン'}},
  {freq: 0, shiin: '짠', ruby: {ja: 'ッチャン'}},
  {freq: 0, shiin: '멓', ruby: {ja: 'モッ'}},
  {freq: 0, shiin: '첨', ruby: {ja: 'チョム'}},
  {freq: 0, shiin: '딘', ruby: {ja: 'ティン'}},
  {freq: 0, shiin: '톡', ruby: {ja: 'トク'}},
  {freq: 0, shiin: '칵', ruby: {ja: 'カク'}},
  {freq: 0, shiin: '텐', ruby: {ja: 'テン'}},
  {freq: 0, shiin: '콧', ruby: {ja: 'コッ'}},
  {freq: 0, shiin: '닌', ruby: {ja: 'ニン'}},
  {freq: 0, shiin: '닙', ruby: {ja: 'ニプ'}},
  {freq: 0, shiin: '쉴', ruby: {ja: 'スィル'}},
  {freq: 0, shiin: '핫', ruby: {ja: 'ハッ'}},
  {freq: 0, shiin: '헐', ruby: {ja: 'ホル'}},
  {freq: 0, shiin: '잭', ruby: {ja: 'チェク'}},
];
