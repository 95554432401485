import {createContext} from 'react';

export interface ThemeContextProps {
  preferredTheme: string;
  toggleTheme: () => object;
}

export const ThemeContext = createContext({
  preferredTheme: 'light',
  toggleTheme: () => {},
});
