import React, {memo} from 'react';
import {View, StyleSheet, Platform} from 'react-native';
import {Text} from 'react-native-paper';
import {themedWidths} from '../common/constants';
import ThemedIcon from './ThemedIcon';

const GuideMenu = () => {
  return (
    <View style={[styles.view, {width: themedWidths.w0905}]}>
      <View style={styles.icon}>
        <ThemedIcon name={'magnify'} size={15} />
        <Text variant="labelSmall" style={styles.icon}>
          {`  `}履歴を保存する（⬇︎）
        </Text>
      </View>
      <View style={styles.icon}>
        <ThemedIcon name={'volume-high'} size={15} />
        <Text variant="labelSmall" style={styles.icon}>
          {`  `}発音を聞く（⬇︎）
        </Text>
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  view: {flexDirection: 'row', padding: 20, justifyContent: 'space-around'},
  icon: {
    flexDirection: 'row',
    position: 'relative',
    top: Platform.OS === 'web' ? 2 : 0,
  },
});
export default memo(GuideMenu);
