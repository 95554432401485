import React, {memo} from 'react';
import {View} from 'react-native';
import {useTheme} from 'react-native-paper';
import {Ionicons} from '@expo/vector-icons';

export interface ToggleThemeProps {
  color?: string;
  preferredTheme: string;
  toggleTheme(): void;
}

const ToggleTheme = (props: ToggleThemeProps) => {
  const {colors} = useTheme();
  const {color, preferredTheme, toggleTheme} = props;
  const _color = color ? color : colors.primary;

  return (
    <View
      style={{alignItems: 'center', justifyContent: 'center', marginRight: 20}}>
      {preferredTheme === 'dark' ? (
        <Ionicons name="sunny" size={24} color={_color} onPress={toggleTheme} />
      ) : (
        <Ionicons name="moon" size={24} color={_color} onPress={toggleTheme} />
      )}
    </View>
  );
};
export default memo(ToggleTheme);
