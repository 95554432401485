export const hannumber = [
  {freq: 0, shiin: '0', ruby: {ja: '0'}},
  {freq: 0, shiin: '1', ruby: {ja: '1'}},
  {freq: 0, shiin: '2', ruby: {ja: '2'}},
  {freq: 0, shiin: '3', ruby: {ja: '3'}},
  {freq: 0, shiin: '4', ruby: {ja: '4'}},
  {freq: 0, shiin: '5', ruby: {ja: '5'}},
  {freq: 0, shiin: '6', ruby: {ja: '6'}},
  {freq: 0, shiin: '7', ruby: {ja: '7'}},
  {freq: 0, shiin: '8', ruby: {ja: '8'}},
  {freq: 0, shiin: '9', ruby: {ja: '9'}},
];
