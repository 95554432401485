import React, {useMemo, useState, useCallback} from 'react';
import {PaperProvider, MD3DarkTheme, MD3LightTheme} from 'react-native-paper';
import {jtheme} from './src/common/constants';
import {ThemeContext} from './src/common/hooks/themeContext';
import Main from './src/main';

export default function App() {
  const [preferredTheme, setTheme] = useState('light'); // 여기에서 초기값을 설정중

  const toggleTheme = useCallback(() => {
    setTheme(_preferredTheme =>
      _preferredTheme === 'light' ? 'dark' : 'light',
    );
  }, []);

  const preferences = useMemo(
    () => ({preferredTheme, toggleTheme: toggleTheme}),
    [preferredTheme, toggleTheme],
  );

  const paperTheme =
    preferredTheme === 'dark'
      ? {...MD3DarkTheme, colors: jtheme.dark.colors}
      : {...MD3LightTheme, colors: jtheme.light.colors};

  return (
    <ThemeContext.Provider value={preferences}>
      <PaperProvider theme={paperTheme}>
        <Main />
      </PaperProvider>
    </ThemeContext.Provider>
  );
}
