import React, {memo} from 'react';
import {View, StyleSheet} from 'react-native';
import {useTheme, Text} from 'react-native-paper';
import {themedWidths} from '../common/constants';
import ThemedIcon from './ThemedIcon';

const SearchResult = (props: {
  ruby: string[];
  onSpeak: (str: string) => void;
}) => {
  const {colors} = useTheme();
  const _styles = React.useMemo(
    () => [
      styles.container,
      {
        width: themedWidths.w05095,
        borderColor: colors.onPrimaryContainer,
      },
    ],
    [colors.onPrimaryContainer],
  );

  return (
    <View style={_styles}>
      <View style={{flexDirection: 'row'}}>
        <ThemedIcon name={'syllabary-katakana'} />
        <Text
          variant="titleMedium"
          style={{paddingLeft: 10, color: colors.primary}}>
          {/* 타이핑느낌의 애니효과를 위해 유지중 */}
          {props.ruby.length ? props.ruby.map(r => r) : null}
        </Text>
      </View>
      <ThemedIcon
        name={'volume-high'}
        onPress={
          props.ruby?.length
            ? () => props.onSpeak(props.ruby.join(''))
            : () => {}
        }
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 15,
    borderRadius: 30,
    borderWidth: StyleSheet.hairlineWidth,
  },
});
export default memo(SearchResult);
