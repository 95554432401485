// Google SpreadSheets - Topik1 を変換したもの！
// https://docs.google.com/spreadsheets/d/1F5eCc6cQTBY6lEna_oF_fk-VdMxCJIKMa6M4oHWOr20/edit#gid=1298114622

import {hanenglish} from './hansetsusEnglish';
import {hannumber} from './hansetsusNumber';
import {hansimbol} from './hansetsusSimbol';

// ハングル 反切表
// 한글을 자음과 모음으로 나눈 다음에 자음을 초성으로 놓고 모음을 중성으로 놓아서 가로세로로 배열한 표를 말한다.
// https://namu.wiki/w/%ED%95%9C%EA%B8%80%20%EB%B0%98%EC%A0%88%ED%91%9C

// 한글이 가지는 유니코드 값은 AC00부터 D7A3까지며, 총 11,172개의 코드로 모든 한글을 표현할 수 있다

// 자주 쓰이는 한국어 낱말 5800
// https://ko.wiktionary.org/wiki/%EB%B6%80%EB%A1%9D:%EC%9E%90%EC%A3%BC_%EC%93%B0%EC%9D%B4%EB%8A%94_%ED%95%9C%EA%B5%AD%EC%96%B4_%EB%82%B1%EB%A7%90_5800

export interface HansetsuProps {
  freq: number;
  shiin: string;
  ruby: {ja: string};
}

export const hansetsus = [
  ...hansimbol,
  ...hannumber,
  ...hanenglish,
  {freq: 0, shiin: '차', ruby: {ja: 'チャ'}},
  {freq: 0, shiin: '파', ruby: {ja: 'パ'}},
  {freq: 0, shiin: '타', ruby: {ja: 'タ'}},
  {freq: 0, shiin: '카', ruby: {ja: 'カ'}},
  {freq: 0, shiin: '짜', ruby: {ja: 'ッチャ'}},
  {freq: 0, shiin: '빠', ruby: {ja: 'ッパ'}},
  {freq: 0, shiin: '따', ruby: {ja: 'ッタ'}},
  {freq: 0, shiin: '싸', ruby: {ja: 'ッサ'}},
  {freq: 0, shiin: '까', ruby: {ja: 'ッカ'}},
  {freq: 0, shiin: '자', ruby: {ja: 'チャ'}},
  {freq: 0, shiin: '바', ruby: {ja: 'パ'}},
  {freq: 0, shiin: '치', ruby: {ja: 'チ'}},
  {freq: 0, shiin: '피', ruby: {ja: 'ピ'}},
  {freq: 0, shiin: '티', ruby: {ja: 'ティ'}},
  {freq: 0, shiin: '키', ruby: {ja: 'キ'}},
  {freq: 0, shiin: '찌', ruby: {ja: 'ッチ'}},
  {freq: 0, shiin: '삐', ruby: {ja: 'ッピ'}},
  {freq: 0, shiin: '띠', ruby: {ja: 'ッティ'}},
  {freq: 0, shiin: '씨', ruby: {ja: 'ッシ'}},
  {freq: 0, shiin: '끼', ruby: {ja: 'ッキ'}},
  {freq: 0, shiin: '지', ruby: {ja: 'チ'}},
  {freq: 0, shiin: '비', ruby: {ja: 'ピ'}},
  {freq: 0, shiin: '추', ruby: {ja: 'チュ'}},
  {freq: 0, shiin: '푸', ruby: {ja: 'プ'}},
  {freq: 0, shiin: '투', ruby: {ja: 'トゥ'}},
  {freq: 0, shiin: '쿠', ruby: {ja: 'ク'}},
  {freq: 0, shiin: '쭈', ruby: {ja: 'ッチュ'}},
  {freq: 0, shiin: '뿌', ruby: {ja: 'ップ'}},
  {freq: 0, shiin: '뚜', ruby: {ja: 'ットゥ'}},
  {freq: 0, shiin: '쑤', ruby: {ja: 'ッス'}},
  {freq: 0, shiin: '꾸', ruby: {ja: 'ック'}},
  {freq: 0, shiin: '주', ruby: {ja: 'チュ'}},
  {freq: 0, shiin: '부', ruby: {ja: 'プ'}},
  {freq: 0, shiin: '츠', ruby: {ja: 'チュ'}},
  {freq: 0, shiin: '프', ruby: {ja: 'プ'}},
  {freq: 0, shiin: '트', ruby: {ja: 'トゥ'}},
  {freq: 0, shiin: '크', ruby: {ja: 'ク'}},
  {freq: 0, shiin: '쯔', ruby: {ja: 'ッチュ'}},
  {freq: 0, shiin: '쁘', ruby: {ja: 'ップ'}},
  {freq: 0, shiin: '뜨', ruby: {ja: 'ットゥ'}},
  {freq: 0, shiin: '쓰', ruby: {ja: 'ッス'}},
  {freq: 0, shiin: '끄', ruby: {ja: 'ック'}},
  {freq: 0, shiin: '즈', ruby: {ja: 'チュ'}},
  {freq: 0, shiin: '브', ruby: {ja: 'プ'}},
  {freq: 0, shiin: '채', ruby: {ja: 'チェ'}},
  {freq: 0, shiin: '패', ruby: {ja: 'ペ'}},
  {freq: 0, shiin: '태', ruby: {ja: 'テ'}},
  {freq: 0, shiin: '캐', ruby: {ja: 'ケ'}},
  {freq: 0, shiin: '째', ruby: {ja: 'ッチェ'}},
  {freq: 0, shiin: '빼', ruby: {ja: 'ッペ'}},
  {freq: 0, shiin: '때', ruby: {ja: 'ッテ'}},
  {freq: 0, shiin: '쌔', ruby: {ja: 'ッセ'}},
  {freq: 0, shiin: '깨', ruby: {ja: 'ッケ'}},
  {freq: 0, shiin: '재', ruby: {ja: 'チェ'}},
  {freq: 0, shiin: '배', ruby: {ja: 'ペ'}},
  {freq: 0, shiin: '체', ruby: {ja: 'チェ'}},
  {freq: 0, shiin: '페', ruby: {ja: 'ペ'}},
  {freq: 0, shiin: '테', ruby: {ja: 'テ'}},
  {freq: 0, shiin: '케', ruby: {ja: 'ケ'}},
  {freq: 0, shiin: '쩨', ruby: {ja: 'ッチェ'}},
  {freq: 0, shiin: '뻬', ruby: {ja: 'ッペ'}},
  {freq: 0, shiin: '떼', ruby: {ja: 'ッテ'}},
  {freq: 0, shiin: '쎄', ruby: {ja: 'ッセ'}},
  {freq: 0, shiin: '께', ruby: {ja: 'ッケ'}},
  {freq: 0, shiin: '제', ruby: {ja: 'チェ'}},
  {freq: 0, shiin: '베', ruby: {ja: 'ペ'}},
  {freq: 0, shiin: '처', ruby: {ja: 'チョ'}},
  {freq: 0, shiin: '퍼', ruby: {ja: 'ポ'}},
  {freq: 0, shiin: '터', ruby: {ja: 'ト'}},
  {freq: 0, shiin: '커', ruby: {ja: 'コ'}},
  {freq: 0, shiin: '쩌', ruby: {ja: 'ッチョ'}},
  {freq: 0, shiin: '뻐', ruby: {ja: 'ッポ'}},
  {freq: 0, shiin: '떠', ruby: {ja: 'ット'}},
  {freq: 0, shiin: '써', ruby: {ja: 'ッソ'}},
  {freq: 0, shiin: '꺼', ruby: {ja: 'ッコ'}},
  {freq: 0, shiin: '저', ruby: {ja: 'チョ'}},
  {freq: 0, shiin: '쪄', ruby: {ja: 'ッチョ'}},
  {freq: 0, shiin: '버', ruby: {ja: 'ポ'}},
  {freq: 0, shiin: '초', ruby: {ja: 'チョ'}},
  {freq: 0, shiin: '포', ruby: {ja: 'ポ'}},
  {freq: 0, shiin: '토', ruby: {ja: 'ト'}},
  {freq: 0, shiin: '코', ruby: {ja: 'コ'}},
  {freq: 0, shiin: '쪼', ruby: {ja: 'ッチョ'}},
  {freq: 0, shiin: '뽀', ruby: {ja: 'ッポ'}},
  {freq: 0, shiin: '또', ruby: {ja: 'ット'}},
  {freq: 0, shiin: '쏘', ruby: {ja: 'ッソ'}},
  {freq: 0, shiin: '꼬', ruby: {ja: 'ッコ'}},
  {freq: 0, shiin: '조', ruby: {ja: 'チョ'}},
  {freq: 0, shiin: '보', ruby: {ja: 'ポ'}},
  {freq: 0, shiin: '챠', ruby: {ja: 'チャ'}},
  {freq: 0, shiin: '퍄', ruby: {ja: 'ピャ'}},
  {freq: 0, shiin: '탸', ruby: {ja: 'ティャ'}},
  {freq: 0, shiin: '캬', ruby: {ja: 'キャ'}},
  {freq: 0, shiin: '쨔', ruby: {ja: 'ッチャ'}},
  {freq: 0, shiin: '뺘', ruby: {ja: 'ッピャ'}},
  {freq: 0, shiin: '땨', ruby: {ja: 'ッティャ'}},
  {freq: 0, shiin: '쌰', ruby: {ja: 'ッシャ'}},
  {freq: 0, shiin: '꺄', ruby: {ja: 'ッキャ'}},
  {freq: 0, shiin: '쟈', ruby: {ja: 'チャ'}},
  {freq: 0, shiin: '뱌', ruby: {ja: 'ピャ'}},
  {freq: 0, shiin: '츄', ruby: {ja: 'チュ'}},
  {freq: 0, shiin: '퓨', ruby: {ja: 'ピュ'}},
  {freq: 0, shiin: '튜', ruby: {ja: 'テュ'}},
  {freq: 0, shiin: '큐', ruby: {ja: 'キュ'}},
  {freq: 0, shiin: '쮸', ruby: {ja: 'ッチュ'}},
  {freq: 0, shiin: '쀼', ruby: {ja: 'ッピュ'}},
  {freq: 0, shiin: '뜌', ruby: {ja: 'ッテュ'}},
  {freq: 0, shiin: '쓔', ruby: {ja: 'ッシュ'}},
  {freq: 0, shiin: '뀨', ruby: {ja: 'ッキュ'}},
  {freq: 0, shiin: '쥬', ruby: {ja: 'チュ'}},
  {freq: 0, shiin: '뷰', ruby: {ja: 'ピュ'}},
  {freq: 0, shiin: '쳐', ruby: {ja: 'チョ'}},
  {freq: 0, shiin: '펴', ruby: {ja: 'ピョ'}},
  {freq: 0, shiin: '텨', ruby: {ja: 'ティョ'}},
  {freq: 0, shiin: '켜', ruby: {ja: 'キョ'}},
  {freq: 0, shiin: '쪄', ruby: {ja: 'ッチョ'}},
  {freq: 0, shiin: '뼈', ruby: {ja: 'ッピョ'}},
  {freq: 0, shiin: '뗘', ruby: {ja: 'ッティョ'}},
  {freq: 0, shiin: '쎠', ruby: {ja: 'ッショ'}},
  {freq: 0, shiin: '껴', ruby: {ja: 'ッキョ'}},
  {freq: 0, shiin: '져', ruby: {ja: 'チョ'}},
  {freq: 0, shiin: '벼', ruby: {ja: 'ピョ'}},
  {freq: 0, shiin: '쵸', ruby: {ja: 'チョ'}},
  {freq: 0, shiin: '표', ruby: {ja: 'ピョ'}},
  {freq: 0, shiin: '툐', ruby: {ja: 'ティョ'}},
  {freq: 0, shiin: '쿄', ruby: {ja: 'キョ'}},
  {freq: 0, shiin: '쬬', ruby: {ja: 'ッチョ'}},
  {freq: 0, shiin: '뾰', ruby: {ja: 'ッピョ'}},
  {freq: 0, shiin: '뚀', ruby: {ja: 'ッティョ'}},
  {freq: 0, shiin: '쑈', ruby: {ja: 'ッショ'}},
  {freq: 0, shiin: '꾜', ruby: {ja: 'ッキョ'}},
  {freq: 0, shiin: '죠', ruby: {ja: 'チョ'}},
  {freq: 0, shiin: '뵤', ruby: {ja: 'ピョ'}},
  {freq: 0, shiin: '촤', ruby: {ja: 'チョヮ'}},
  {freq: 0, shiin: '퐈', ruby: {ja: 'ポヮ'}},
  {freq: 0, shiin: '톼', ruby: {ja: 'トヮ'}},
  {freq: 0, shiin: '콰', ruby: {ja: 'コヮ'}},
  {freq: 0, shiin: '쫘', ruby: {ja: 'ッチョヮ'}},
  {freq: 0, shiin: '뽜', ruby: {ja: 'ッポヮ'}},
  {freq: 0, shiin: '똬', ruby: {ja: 'ットヮ'}},
  {freq: 0, shiin: '쏴', ruby: {ja: 'ッソヮ'}},
  {freq: 0, shiin: '꽈', ruby: {ja: 'ッコヮ'}},
  {freq: 0, shiin: '좌', ruby: {ja: 'チョヮ'}},
  {freq: 0, shiin: '봐', ruby: {ja: 'ポヮ'}},
  {freq: 0, shiin: '챼', ruby: {ja: 'チィェ'}},
  {freq: 0, shiin: '퍠', ruby: {ja: 'ピィェ'}},
  {freq: 0, shiin: '턔', ruby: {ja: 'ティェ'}},
  {freq: 0, shiin: '컈', ruby: {ja: 'キィェ'}},
  {freq: 0, shiin: '쨰', ruby: {ja: 'ッチィェ'}},
  {freq: 0, shiin: '뺴', ruby: {ja: 'ッピィェ'}},
  {freq: 0, shiin: '떄', ruby: {ja: 'ッティェ'}},
  {freq: 0, shiin: '썌', ruby: {ja: 'ッシィェ'}},
  {freq: 0, shiin: '꺠', ruby: {ja: 'ッキィェ'}},
  {freq: 0, shiin: '쟤', ruby: {ja: 'チィェ'}},
  {freq: 0, shiin: '뱨', ruby: {ja: 'ピィェ'}},
  {freq: 0, shiin: '쳬', ruby: {ja: 'チェェ'}},
  {freq: 0, shiin: '폐', ruby: {ja: 'ペェ'}},
  {freq: 0, shiin: '톄', ruby: {ja: 'テェ'}},
  {freq: 0, shiin: '켸', ruby: {ja: 'ケェ'}},
  {freq: 0, shiin: '쪠', ruby: {ja: 'ッチェェ'}},
  {freq: 0, shiin: '뼤', ruby: {ja: 'ッペェ'}},
  {freq: 0, shiin: '뗴', ruby: {ja: 'ッテェ'}},
  {freq: 0, shiin: '쎼', ruby: {ja: 'ッシェェ'}},
  {freq: 0, shiin: '꼐', ruby: {ja: 'ッケェ'}},
  {freq: 0, shiin: '졔', ruby: {ja: 'チェェ'}},
  {freq: 0, shiin: '볘', ruby: {ja: 'ペェ'}},
  {freq: 0, shiin: '취', ruby: {ja: 'チュィ'}},
  {freq: 0, shiin: '퓌', ruby: {ja: 'プィ'}},
  {freq: 0, shiin: '튀', ruby: {ja: 'トゥィ'}},
  {freq: 0, shiin: '퀴', ruby: {ja: 'クィ'}},
  {freq: 0, shiin: '쮜', ruby: {ja: 'ッチュィ'}},
  {freq: 0, shiin: '쀠', ruby: {ja: 'ップィ'}},
  {freq: 0, shiin: '뛰', ruby: {ja: 'ットゥィ'}},
  {freq: 0, shiin: '쒸', ruby: {ja: 'ッスィ'}},
  {freq: 0, shiin: '뀌', ruby: {ja: 'ックィ'}},
  {freq: 0, shiin: '쥐', ruby: {ja: 'チュィ'}},
  {freq: 0, shiin: '뷔', ruby: {ja: 'プィ'}},
  {freq: 0, shiin: '츼', ruby: {ja: 'チュィ'}},
  {freq: 0, shiin: '픠', ruby: {ja: 'プィ'}},
  {freq: 0, shiin: '틔', ruby: {ja: 'トゥィ'}},
  {freq: 0, shiin: '킈', ruby: {ja: 'クィ'}},
  {freq: 0, shiin: '쯰', ruby: {ja: 'ッチュィ'}},
  {freq: 0, shiin: '쁴', ruby: {ja: 'ップィ'}},
  {freq: 0, shiin: '띄', ruby: {ja: 'ットゥィ'}},
  {freq: 0, shiin: '씌', ruby: {ja: 'ッスィ'}},
  {freq: 0, shiin: '끠', ruby: {ja: 'ックィ'}},
  {freq: 0, shiin: '즤', ruby: {ja: 'チュィ'}},
  {freq: 0, shiin: '븨', ruby: {ja: 'ピィ'}},
  {freq: 0, shiin: '쵀', ruby: {ja: 'チュェ'}},
  {freq: 0, shiin: '퐤', ruby: {ja: 'プェ'}},
  {freq: 0, shiin: '퇘', ruby: {ja: 'トゥェ'}},
  {freq: 0, shiin: '쾌', ruby: {ja: 'クェ'}},
  {freq: 0, shiin: '쫴', ruby: {ja: 'ッチュェ'}},
  {freq: 0, shiin: '뽸', ruby: {ja: 'ップェ'}},
  {freq: 0, shiin: '뙈', ruby: {ja: 'ットゥェ'}},
  {freq: 0, shiin: '쐐', ruby: {ja: 'ッスェ'}},
  {freq: 0, shiin: '꽤', ruby: {ja: 'ックェ'}},
  {freq: 0, shiin: '좨', ruby: {ja: 'チュェ'}},
  {freq: 0, shiin: '봬', ruby: {ja: 'ポェ'}},
  {freq: 0, shiin: '최', ruby: {ja: 'チュェ'}},
  {freq: 0, shiin: '푀', ruby: {ja: 'プェ'}},
  {freq: 0, shiin: '퇴', ruby: {ja: 'トゥェ'}},
  {freq: 0, shiin: '쾨', ruby: {ja: 'クェ'}},
  {freq: 0, shiin: '쬐', ruby: {ja: 'ッチュェ'}},
  {freq: 0, shiin: '뾔', ruby: {ja: 'ップェ'}},
  {freq: 0, shiin: '뙤', ruby: {ja: 'ットゥェ'}},
  {freq: 0, shiin: '쐬', ruby: {ja: 'ッスェ'}},
  {freq: 0, shiin: '꾀', ruby: {ja: 'クェ'}},
  {freq: 0, shiin: '죄', ruby: {ja: 'チュェ'}},
  {freq: 0, shiin: '뵈', ruby: {ja: 'ポェ'}},
  {freq: 0, shiin: '췌', ruby: {ja: 'チュェ'}},
  {freq: 0, shiin: '풰', ruby: {ja: 'プェ'}},
  {freq: 0, shiin: '퉤', ruby: {ja: 'トゥェ'}},
  {freq: 0, shiin: '퀘', ruby: {ja: 'クェ'}},
  {freq: 0, shiin: '쮀', ruby: {ja: 'ッチュェ'}},
  {freq: 0, shiin: '쀄', ruby: {ja: 'ップェ'}},
  {freq: 0, shiin: '뛔', ruby: {ja: 'ットゥェ'}},
  {freq: 0, shiin: '쒜', ruby: {ja: 'ッスェ'}},
  {freq: 0, shiin: '꿰', ruby: {ja: 'ックェ'}},
  {freq: 0, shiin: '줴', ruby: {ja: 'チュェ'}},
  {freq: 0, shiin: '붸', ruby: {ja: 'プェ'}},
  {freq: 0, shiin: '춰', ruby: {ja: 'チュォ'}},
  {freq: 0, shiin: '풔', ruby: {ja: 'プォ'}},
  {freq: 0, shiin: '퉈', ruby: {ja: 'トゥォ'}},
  {freq: 0, shiin: '쿼', ruby: {ja: 'クォ'}},
  {freq: 0, shiin: '쭤', ruby: {ja: 'ッチュォ'}},
  {freq: 0, shiin: '뿨', ruby: {ja: 'ップォ'}},
  {freq: 0, shiin: '뚸', ruby: {ja: 'ットゥォ'}},
  {freq: 0, shiin: '쒀', ruby: {ja: 'ッスォ'}},
  {freq: 0, shiin: '꿔', ruby: {ja: 'ックォ'}},
  {freq: 0, shiin: '줘', ruby: {ja: 'チュォ'}},
  {freq: 0, shiin: '붜', ruby: {ja: 'プォ'}},
  {freq: 0, shiin: '뤄', ruby: {ja: 'ルォ'}},
  {freq: 0, shiin: '라', ruby: {ja: 'ラ'}},
  {freq: 0, shiin: '마', ruby: {ja: 'マ'}},
  {freq: 0, shiin: '하', ruby: {ja: 'ハ'}},
  {freq: 0, shiin: '나', ruby: {ja: 'ナ'}},
  {freq: 0, shiin: '다', ruby: {ja: 'タ'}},
  {freq: 0, shiin: '사', ruby: {ja: 'サ'}},
  {freq: 0, shiin: '가', ruby: {ja: 'カ'}},
  {freq: 0, shiin: '아', ruby: {ja: 'ア'}},
  {freq: 0, shiin: '리', ruby: {ja: 'リ'}},
  {freq: 0, shiin: '미', ruby: {ja: 'ミ'}},
  {freq: 0, shiin: '히', ruby: {ja: 'ヒ'}},
  {freq: 0, shiin: '니', ruby: {ja: 'ニ'}},
  {freq: 0, shiin: '디', ruby: {ja: 'ティ'}},
  {freq: 0, shiin: '시', ruby: {ja: 'シ'}},
  {freq: 0, shiin: '기', ruby: {ja: 'キ'}},
  {freq: 0, shiin: '이', ruby: {ja: 'イ'}},
  {freq: 0, shiin: '루', ruby: {ja: 'ル'}},
  {freq: 0, shiin: '무', ruby: {ja: 'ム'}},
  {freq: 0, shiin: '후', ruby: {ja: 'フ'}},
  {freq: 0, shiin: '누', ruby: {ja: 'ヌ'}},
  {freq: 0, shiin: '두', ruby: {ja: 'トゥ'}},
  {freq: 0, shiin: '수', ruby: {ja: 'ス'}},
  {freq: 0, shiin: '구', ruby: {ja: 'ク'}},
  {freq: 0, shiin: '우', ruby: {ja: 'ウ'}},
  {freq: 0, shiin: '르', ruby: {ja: 'ル'}},
  {freq: 0, shiin: '므', ruby: {ja: 'ム'}},
  {freq: 0, shiin: '흐', ruby: {ja: 'フ'}},
  {freq: 0, shiin: '느', ruby: {ja: 'ヌ'}},
  {freq: 0, shiin: '드', ruby: {ja: 'トゥ'}},
  {freq: 0, shiin: '스', ruby: {ja: 'ス'}},
  {freq: 0, shiin: '그', ruby: {ja: 'ク'}},
  {freq: 0, shiin: '으', ruby: {ja: 'ウ'}},
  {freq: 0, shiin: '래', ruby: {ja: 'レ'}},
  {freq: 0, shiin: '매', ruby: {ja: 'メ'}},
  {freq: 0, shiin: '해', ruby: {ja: 'ヘ'}},
  {freq: 0, shiin: '내', ruby: {ja: 'ネ'}},
  {freq: 0, shiin: '대', ruby: {ja: 'テ'}},
  {freq: 0, shiin: '새', ruby: {ja: 'セ'}},
  {freq: 0, shiin: '개', ruby: {ja: 'ケ'}},
  {freq: 0, shiin: '애', ruby: {ja: 'エ'}},
  {freq: 0, shiin: '레', ruby: {ja: 'レ'}},
  {freq: 0, shiin: '메', ruby: {ja: 'メ'}},
  {freq: 0, shiin: '헤', ruby: {ja: 'ヘ'}},
  {freq: 0, shiin: '네', ruby: {ja: 'ネ'}},
  {freq: 0, shiin: '데', ruby: {ja: 'テ'}},
  {freq: 0, shiin: '세', ruby: {ja: 'セ'}},
  {freq: 0, shiin: '게', ruby: {ja: 'ケ'}},
  {freq: 0, shiin: '에', ruby: {ja: 'エ'}},
  {freq: 0, shiin: '러', ruby: {ja: 'ロ'}},
  {freq: 0, shiin: '머', ruby: {ja: 'モ'}},
  {freq: 0, shiin: '허', ruby: {ja: 'ホ'}},
  {freq: 0, shiin: '너', ruby: {ja: 'ノ'}},
  {freq: 0, shiin: '더', ruby: {ja: 'ト'}},
  {freq: 0, shiin: '서', ruby: {ja: 'ソ'}},
  {freq: 0, shiin: '거', ruby: {ja: 'コ'}},
  {freq: 0, shiin: '어', ruby: {ja: 'オ'}},
  {freq: 0, shiin: '로', ruby: {ja: 'ロ'}},
  {freq: 0, shiin: '모', ruby: {ja: 'モ'}},
  {freq: 0, shiin: '호', ruby: {ja: 'ホ'}},
  {freq: 0, shiin: '노', ruby: {ja: 'ノ'}},
  {freq: 0, shiin: '도', ruby: {ja: 'ト'}},
  {freq: 0, shiin: '소', ruby: {ja: 'ソ'}},
  {freq: 0, shiin: '고', ruby: {ja: 'コ'}},
  {freq: 0, shiin: '오', ruby: {ja: 'オ'}},
  {freq: 0, shiin: '랴', ruby: {ja: 'リャ'}},
  {freq: 0, shiin: '먀', ruby: {ja: 'ミャ'}},
  {freq: 0, shiin: '햐', ruby: {ja: 'ヒャ'}},
  {freq: 0, shiin: '냐', ruby: {ja: 'ニャ'}},
  {freq: 0, shiin: '댜', ruby: {ja: 'ティヤ'}},
  {freq: 0, shiin: '샤', ruby: {ja: 'シャ'}},
  {freq: 0, shiin: '갸', ruby: {ja: 'キャ'}},
  {freq: 0, shiin: '야', ruby: {ja: 'ヤ'}},
  {freq: 0, shiin: '류', ruby: {ja: 'リュ'}},
  {freq: 0, shiin: '뮤', ruby: {ja: 'ミュ'}},
  {freq: 0, shiin: '휴', ruby: {ja: 'ヒュ'}},
  {freq: 0, shiin: '뉴', ruby: {ja: 'ニュ'}},
  {freq: 0, shiin: '듀', ruby: {ja: 'テュ'}},
  {freq: 0, shiin: '슈', ruby: {ja: 'シュ'}},
  {freq: 0, shiin: '규', ruby: {ja: 'キュ'}},
  {freq: 0, shiin: '유', ruby: {ja: 'ユ'}},
  {freq: 0, shiin: '려', ruby: {ja: 'リョ'}},
  {freq: 0, shiin: '며', ruby: {ja: 'ミョ'}},
  {freq: 0, shiin: '혀', ruby: {ja: 'ヒョ'}},
  {freq: 0, shiin: '녀', ruby: {ja: 'ニョ'}},
  {freq: 0, shiin: '뎌', ruby: {ja: 'ティョ'}},
  {freq: 0, shiin: '셔', ruby: {ja: 'ショ'}},
  {freq: 0, shiin: '겨', ruby: {ja: 'キョ'}},
  {freq: 0, shiin: '여', ruby: {ja: 'ヨ'}},
  {freq: 0, shiin: '료', ruby: {ja: 'リョ'}},
  {freq: 0, shiin: '묘', ruby: {ja: 'ミョ'}},
  {freq: 0, shiin: '효', ruby: {ja: 'ヒョ'}},
  {freq: 0, shiin: '뇨', ruby: {ja: 'ニョ'}},
  {freq: 0, shiin: '됴', ruby: {ja: 'ティョ'}},
  {freq: 0, shiin: '쇼', ruby: {ja: 'ショ'}},
  {freq: 0, shiin: '교', ruby: {ja: 'キョ'}},
  {freq: 0, shiin: '요', ruby: {ja: 'ヨ'}},
  {freq: 0, shiin: '롸', ruby: {ja: 'ロヮ'}},
  {freq: 0, shiin: '뫄', ruby: {ja: 'モヮ'}},
  {freq: 0, shiin: '화', ruby: {ja: 'ホヮ'}},
  {freq: 0, shiin: '놔', ruby: {ja: 'ノヮ'}},
  {freq: 0, shiin: '돠', ruby: {ja: 'トヮ'}},
  {freq: 0, shiin: '솨', ruby: {ja: 'ソヮ'}},
  {freq: 0, shiin: '과', ruby: {ja: 'コヮ'}},
  {freq: 0, shiin: '와', ruby: {ja: 'ワ'}},
  {freq: 0, shiin: '럐', ruby: {ja: 'リィェ'}},
  {freq: 0, shiin: '먜', ruby: {ja: 'ミィェ'}},
  {freq: 0, shiin: '햬', ruby: {ja: 'ヒィェ'}},
  {freq: 0, shiin: '냬', ruby: {ja: 'ニィェ'}},
  {freq: 0, shiin: '댸', ruby: {ja: 'ティェ'}},
  {freq: 0, shiin: '섀', ruby: {ja: 'シィェ'}},
  {freq: 0, shiin: '걔', ruby: {ja: 'キィェ'}},
  {freq: 0, shiin: '얘', ruby: {ja: 'イェ'}},
  {freq: 0, shiin: '례', ruby: {ja: 'レェ'}},
  {freq: 0, shiin: '몌', ruby: {ja: 'メェ'}},
  {freq: 0, shiin: '혜', ruby: {ja: 'ヘェ'}},
  {freq: 0, shiin: '녜', ruby: {ja: 'ネェ'}},
  {freq: 0, shiin: '뎨', ruby: {ja: 'テェ'}},
  {freq: 0, shiin: '셰', ruby: {ja: 'シェェ'}},
  {freq: 0, shiin: '계', ruby: {ja: 'ケェ'}},
  {freq: 0, shiin: '예', ruby: {ja: 'イェ'}},
  {freq: 0, shiin: '뤼', ruby: {ja: 'ルィ'}},
  {freq: 0, shiin: '뮈', ruby: {ja: 'ムィ'}},
  {freq: 0, shiin: '휘', ruby: {ja: 'フィ'}},
  {freq: 0, shiin: '뉘', ruby: {ja: 'ヌィ'}},
  {freq: 0, shiin: '뒤', ruby: {ja: 'トゥィ'}},
  {freq: 0, shiin: '쉬', ruby: {ja: 'スィ'}},
  {freq: 0, shiin: '귀', ruby: {ja: 'クィ'}},
  {freq: 0, shiin: '위', ruby: {ja: 'ウィ'}},
  {freq: 0, shiin: '릐', ruby: {ja: 'ルィ'}},
  {freq: 0, shiin: '믜', ruby: {ja: 'ムィ'}},
  {freq: 0, shiin: '희', ruby: {ja: 'フィ'}},
  {freq: 0, shiin: '늬', ruby: {ja: 'ヌィ'}},
  {freq: 0, shiin: '듸', ruby: {ja: 'トゥィ'}},
  {freq: 0, shiin: '싀', ruby: {ja: 'スィ'}},
  {freq: 0, shiin: '긔', ruby: {ja: 'クィ'}},
  {freq: 0, shiin: '의', ruby: {ja: 'ウィ'}},
  {freq: 0, shiin: '뢔', ruby: {ja: 'ルェ'}},
  {freq: 0, shiin: '뫠', ruby: {ja: 'ムェ'}},
  {freq: 0, shiin: '홰', ruby: {ja: 'フェ'}},
  {freq: 0, shiin: '놰', ruby: {ja: 'ヌェ'}},
  {freq: 0, shiin: '돼', ruby: {ja: 'トゥェ'}},
  {freq: 0, shiin: '쇄', ruby: {ja: 'スェ'}},
  {freq: 0, shiin: '괘', ruby: {ja: 'クェ'}},
  {freq: 0, shiin: '왜', ruby: {ja: 'ウェ'}},
  {freq: 0, shiin: '뢰', ruby: {ja: 'ルェ'}},
  {freq: 0, shiin: '뫼', ruby: {ja: 'ムェ'}},
  {freq: 0, shiin: '회', ruby: {ja: 'フェ'}},
  {freq: 0, shiin: '뇌', ruby: {ja: 'ヌェ'}},
  {freq: 0, shiin: '되', ruby: {ja: 'トゥェ'}},
  {freq: 0, shiin: '쇠', ruby: {ja: 'スェ'}},
  {freq: 0, shiin: '괴', ruby: {ja: 'クェ'}},
  {freq: 0, shiin: '외', ruby: {ja: 'ウェ'}},
  {freq: 0, shiin: '뤠', ruby: {ja: 'ルェ'}},
  {freq: 0, shiin: '뭬', ruby: {ja: 'ムェ'}},
  {freq: 0, shiin: '훼', ruby: {ja: 'フェ'}},
  {freq: 0, shiin: '눼', ruby: {ja: 'ヌェ'}},
  {freq: 0, shiin: '뒈', ruby: {ja: 'トゥェ'}},
  {freq: 0, shiin: '쉐', ruby: {ja: 'スェ'}},
  {freq: 0, shiin: '궤', ruby: {ja: 'クェ'}},
  {freq: 0, shiin: '웨', ruby: {ja: 'ウェ'}},
  {freq: 0, shiin: '뭐', ruby: {ja: 'ムォ'}},
  {freq: 0, shiin: '훠', ruby: {ja: 'フォ'}},
  {freq: 0, shiin: '눠', ruby: {ja: 'ヌォ'}},
  {freq: 0, shiin: '둬', ruby: {ja: 'トゥォ'}},
  {freq: 0, shiin: '숴', ruby: {ja: 'スォ'}},
  {freq: 0, shiin: '궈', ruby: {ja: 'クォ'}},
  {freq: 0, shiin: '워', ruby: {ja: 'ウォ'}},
];
