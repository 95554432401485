import React, {memo} from 'react';
import {View, StyleSheet, TouchableOpacity} from 'react-native';
import {Text, useTheme} from 'react-native-paper';
import * as Linking from 'expo-linking';
import {MaterialCommunityIcons} from '@expo/vector-icons';
import {URL} from '../common/constants';
import KotangoIcon from './KotangoIcon';

const JFooter = () => {
  const {colors} = useTheme();
  const date = new Date();
  const fy = date.getFullYear();
  const _copyright = React.useMemo(() => {
    return fy > 2023 ? `© 2023 - ${fy} jacepark.com` : `© ${fy} jacepark.com`;
  }, [fy]);
  return (
    <View style={[styles.container, {backgroundColor: colors.background}]}>
      <TouchableOpacity
        style={{flexDirection: 'row', alignItems: 'center', marginBottom: 10}}
        onPress={() => Linking.openURL('https://kotango.iffthen.com')}>
        <KotangoIcon size={18} />
        <Text>ハングル単語学習アプリ〜Kotango〜</Text>
      </TouchableOpacity>
      <TouchableOpacity onPress={() => Linking.openURL(URL.contact)}>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <MaterialCommunityIcons
            name="email-send-outline"
            size={18}
            color={colors.onSurface}
          />
          <Text variant="bodySmall"> お問合せ</Text>
        </View>
      </TouchableOpacity>
      <Text variant="bodySmall">{_copyright}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {padding: 20, alignItems: 'center', justifyContent: 'center'},
});
export default memo(JFooter);
