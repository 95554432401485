/**
 * https://callstack.github.io/react-native-paper/docs/guides/theming/#using-schemes
 *
 * Primary: #00796B
 * Secondary: #7B1FA2
 * Tertiary: ##616161
 */

export const jtheme = {
  light: {
    colors: {
      primary: 'rgb(0, 107, 94)',
      onPrimary: 'rgb(255, 255, 255)',
      primaryContainer: 'rgb(118, 248, 226)',
      onPrimaryContainer: 'rgb(0, 32, 27)',
      secondary: 'rgb(140, 51, 179)',
      onSecondary: 'rgb(255, 255, 255)',
      secondaryContainer: 'rgb(248, 216, 255)',
      onSecondaryContainer: 'rgb(50, 0, 71)',
      tertiary: 'rgb(0, 104, 116)',
      onTertiary: 'rgb(255, 255, 255)',
      tertiaryContainer: 'rgb(151, 240, 255)',
      onTertiaryContainer: 'rgb(0, 31, 36)',
      error: 'rgb(186, 26, 26)',
      onError: 'rgb(255, 255, 255)',
      errorContainer: 'rgb(255, 218, 214)',
      onErrorContainer: 'rgb(65, 0, 2)',
      background: 'rgb(250, 253, 251)',
      onBackground: 'rgb(25, 28, 27)',
      surface: 'rgb(250, 253, 251)',
      onSurface: 'rgb(25, 28, 27)',
      surfaceVariant: 'rgb(218, 229, 225)',
      onSurfaceVariant: 'rgb(63, 73, 70)',
      outline: 'rgb(111, 121, 118)',
      outlineVariant: 'rgb(190, 201, 197)',
      shadow: 'rgb(0, 0, 0)',
      scrim: 'rgb(0, 0, 0)',
      inverseSurface: 'rgb(45, 49, 48)',
      inverseOnSurface: 'rgb(239, 241, 239)',
      inversePrimary: 'rgb(85, 219, 198)',
      elevation: {
        level0: 'transparent',
        level1: 'rgb(238, 246, 243)',
        level2: 'rgb(230, 241, 238)',
        level3: 'rgb(223, 237, 234)',
        level4: 'rgb(220, 236, 232)',
        level5: 'rgb(215, 233, 229)',
      },
      surfaceDisabled: 'rgba(25, 28, 27, 0.12)',
      onSurfaceDisabled: 'rgba(25, 28, 27, 0.38)',
      backdrop: 'rgba(41, 50, 48, 0.4)',
    },
  },
  dark: {
    colors: {
      primary: 'rgb(85, 219, 198)',
      onPrimary: 'rgb(0, 55, 48)',
      primaryContainer: 'rgb(0, 80, 71)',
      onPrimaryContainer: 'rgb(118, 248, 226)',
      secondary: 'rgb(235, 178, 255)',
      onSecondary: 'rgb(82, 0, 113)',
      secondaryContainer: 'rgb(114, 17, 153)',
      onSecondaryContainer: 'rgb(248, 216, 255)',
      tertiary: 'rgb(79, 216, 235)',
      onTertiary: 'rgb(0, 54, 61)',
      tertiaryContainer: 'rgb(0, 79, 88)',
      onTertiaryContainer: 'rgb(151, 240, 255)',
      error: 'rgb(255, 180, 171)',
      onError: 'rgb(105, 0, 5)',
      errorContainer: 'rgb(147, 0, 10)',
      onErrorContainer: 'rgb(255, 180, 171)',
      background: 'rgb(25, 28, 27)',
      onBackground: 'rgb(224, 227, 225)',
      surface: 'rgb(25, 28, 27)',
      onSurface: 'rgb(224, 227, 225)',
      surfaceVariant: 'rgb(63, 73, 70)',
      onSurfaceVariant: 'rgb(190, 201, 197)',
      outline: 'rgb(137, 147, 144)',
      outlineVariant: 'rgb(63, 73, 70)',
      shadow: 'rgb(0, 0, 0)',
      scrim: 'rgb(0, 0, 0)',
      inverseSurface: 'rgb(224, 227, 225)',
      inverseOnSurface: 'rgb(45, 49, 48)',
      inversePrimary: 'rgb(0, 107, 94)',
      elevation: {
        level0: 'transparent',
        level1: 'rgb(28, 38, 36)',
        level2: 'rgb(30, 43, 41)',
        level3: 'rgb(32, 49, 46)',
        level4: 'rgb(32, 51, 48)',
        level5: 'rgb(33, 55, 51)',
      },
      surfaceDisabled: 'rgba(224, 227, 225, 0.12)',
      onSurfaceDisabled: 'rgba(224, 227, 225, 0.38)',
      backdrop: 'rgba(41, 50, 48, 0.4)',
    },
  },
};
