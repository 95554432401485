export const hansimbol = [
  {freq: 0, shiin: '-', ruby: {ja: '-'}},
  {freq: 0, shiin: ':', ruby: {ja: '：'}},
  {freq: 0, shiin: '!', ruby: {ja: '！'}},
  {freq: 0, shiin: '?', ruby: {ja: '？'}},
  {freq: 0, shiin: '(', ruby: {ja: '('}},
  {freq: 0, shiin: ')', ruby: {ja: ')'}},
  {freq: 0, shiin: '・', ruby: {ja: '・'}},
  {freq: 0, shiin: ' ', ruby: {ja: ' '}},
];
