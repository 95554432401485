import React, {memo} from 'react';
import {View, StyleSheet, Pressable} from 'react-native';
import {useTheme} from 'react-native-paper';
import {MaterialCommunityIcons} from '@expo/vector-icons';

type IconProps =
  | 'syllabary-hangul'
  | 'syllabary-katakana'
  | 'magnify'
  | 'volume-high'
  | 'magnify-close';

const ThemedIcon = (props: {
  size?: number;
  name: IconProps;
  onPress?: () => void;
}) => {
  const {colors} = useTheme();
  const _iconColor = React.useMemo(() => colors.onSurface, [colors.onSurface]);

  return (
    <Pressable
      onPress={props.onPress}
      style={({pressed}) => [
        {
          opacity: pressed ? 0.3 : 1,
          borderRadius: 20,
          backgroundColor: pressed
            ? colors.primaryContainer
            : colors.background,
        },
      ]}>
      <View
        style={{
          borderWidth: StyleSheet.hairlineWidth,
          borderColor: colors.outline,
          borderRadius: props.size ? props.size : 20,
          padding: 2,
        }}>
        <MaterialCommunityIcons
          name={props.name}
          size={props.size ? props.size : 20} // katakana(22)보다 크게 설정중
          color={_iconColor}
        />
      </View>
    </Pressable>
  );
};

export default memo(ThemedIcon);
